import React, { useState, useRef, useEffect, useContext } from 'react';
import { motion } from 'framer-motion';
import retailImg from '../../shared/img/artificial-photography-vB5qtt8X4NA-unsplash.jpg';
import hospitalityImg from '../../shared/img/alex-haney-CAhjZmVk5H4-unsplash.jpg';
import healthImg from '../../shared/img/markus-frieauff-IJ0KiXl4uys-unsplash.jpg';
import hrImg from '../../shared/img/luis-villasmil-4V8uMZx8FYA-unsplash.jpg';
import sportsImg from '../../shared/img/adrian-curiel-uS4an493LkY-unsplash.jpg';
import publicImg from '../../shared/img/element5-digital-ls8Kc0P9hAA-unsplash.jpg';
import TabData from '../../data/tabs.json';
import { HeroModalContext } from '../../context/heroModal';
import UseCaseTemplate from '../../components/useCaseTemplate';

const INDUSTRY_DATA = {
    'Retail': {
        image: retailImg,
        title: 'Retail',
        description: 'Retail industry focuses on selling consumer goods to end customers.',
    },
    'Hospitality': {
        image: hospitalityImg,
        title: 'Hospitality',
        description: 'The hospitality industry includes businesses like hotels, restaurants, and travel services.',
    },
    'Healthcare': {
        image: healthImg,
        title: 'Healthcare',
        description: 'Healthcare encompasses medical practices, hospitals, and research.',
    },
    'Sports Entertainment': {
        image: sportsImg,
        title: 'Sports Entertainment',
        description: 'This industry focuses on sports and recreational activities for entertainment.',
    },
    'Human Resources': {
        image: hrImg,
        title: 'Human Resources',
        description: 'HR deals with recruitment, training, and employee welfare.',
    },
    'Public Services': {
        image: publicImg,
        title: 'Public Services',
        description: 'Public services include government and municipal services to the public.',
    },
};


const TAB_TYPES = {
    USE_CASE: 'useCase',
    BENEFITS: 'Benefits'
};

const IndustryCard = () => {
    const { 
        isHeroModalOpen, 
        setIsHeroModalOpen, 
        heroModalComponent, 
        setHeroModalComponent
    } = useContext(HeroModalContext);

    const [expandedIndustry, setExpandedIndustry] = useState(null);
    const [activeTab, setActiveTab] = useState({industry: null, type: 'benefits'});
    const [showModalContent, setShowModalContent] = useState('default'); 
    const [selectedIndustry, setSelectedIndustry] = useState(null);
    const [modalHistory, setModalHistory] = useState(['BEFEFITS']);
    const TAB_TYPES = {
        USE_CASE: 'usecase',
        BENEFITS: 'benefits'
    };

    function importAll(r) {
        let imageModules = {};
        r.keys().forEach((item) => { 
            imageModules[item.replace('./', '')] = r(item); 
        });
        return imageModules;
    }
    const handleBack = () => {
        setModalHistory(prevHistory => {
            if (prevHistory.length <= 1) return prevHistory; // Ensure you don't remove the last state
            return prevHistory.slice(0, -1); // Remove the last state
        });
    };
    const images = importAll(require.context('../../shared/img', false, /\.(png|jpe?g|svg)$/));

    const handleCardClick = industryKey => {
        console.log('Active Tab after card click:', activeTab);
        console.log('Active Industry:', industryKey);
        if (expandedIndustry === industryKey) {
            setExpandedIndustry(null);
        } else {
            setExpandedIndustry(industryKey);
            setActiveTab({industry: industryKey, type: 'benefits'});
        }
    };
    

    const handleCloseClick = () => {
        setExpandedIndustry(null);
    };

    const renderTabContent = () => {
        const currentTab = TabData.find(t => 
            t.industry.toLowerCase() === activeTab.industry.toLowerCase() && 
            t.type.toLowerCase() === activeTab.type.toLowerCase()
        );
    
        console.log("Found currentTab:", currentTab);
    
        if (!currentTab) return null;
    
        const industry = currentTab.industry;
        const type = currentTab.type.toLowerCase();
    
        console.log("Extracted Industry:", industry);
        console.log("Extracted Type:", type);
    
        switch (type) {
            case TAB_TYPES.USE_CASE:
                console.log("Rendering UseCaseTemplate for industry:", industry);  // <-- Added log
                return (
                    <UseCaseTemplate
                        industry={industry}
                        onClose={handleCloseClick}
                    />
                );
            case TAB_TYPES.BENEFITS:
                return (
                    <div>
                        {currentTab.points.map((point, index) => (
                            <div key={index}>
                                <div style={{ marginTop: '50px' }} className="title-centered" dangerouslySetInnerHTML={{ __html: point.title }} />
                                
                                <img src={images[point.image]} alt="" className="benefits-image-formatted" />
                                <p style={{ marginTop: '50px' }} className="bambu_tm_expanded-description" dangerouslySetInnerHTML={{ __html: point.description }} />
                            </div>
                        ))}
                        <div className="bambu_tm-topbar-button-container">
                            <button onClick={handleBack} className="bambu_tm_btn">Back</button>
                            <button 
                                className="bambu_tm_btn-green"
                                onClick={(e) => {
                                    setShowModalContent('useCaseTemplate');
                                    setSelectedIndustry(industry);
                                    setActiveTab({industry: industry, type: 'useCase'});
                                    e.stopPropagation();
                                }}
                            >
                                Try it yourself
                            </button>
                        </div>
                    </div>
                );
            default:
                console.log("Neither UseCase nor Benefits type found. Type:", type);  // <-- Added log
                return null;
        }
    };

    const modalContentRef = useRef(null);

    const handleClickOutside = event => {
        if (modalContentRef.current && !modalContentRef.current.contains(event.target)) {
            setExpandedIndustry(null);
        }
    };

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    return (
        <div className="bambu_tm-industry-card-container">
          {Object.keys(INDUSTRY_DATA).map(industryKey => {
            const imagePath = INDUSTRY_DATA[industryKey];
            //const tabs = industryFromTab(industryKey);
            const isExpanded = expandedIndustry === industryKey;
            
            return (
              <motion.div
                key={industryKey}
                className={`bambu_tm-industry-card ${isExpanded ? 'expanded-card-col' : 'card-col'}`}
                onClick={() => handleCardClick(industryKey)}
                initial={{ opacity: 0, y: 30 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: -30 }}
                transition={{ duration: 0.3 }}
              >
                {isExpanded ? (
                  <div className="bambu_tm-industry-card expanded">
                    <div ref={modalContentRef}>
                    <div className="tab-content">
                        {renderTabContent(activeTab)}
                    </div>
                    
                    </div>

                  </div>
                ) : (
                  <motion.div
                    ref={modalContentRef}
                    initial={{ opacity: 0, y: 30 }}
                    animate={{ opacity: 1, y: 0 }}
                    exit={{ opacity: 0, y: -30 }}
                    transition={{ duration: 0.3 }}
                  >
                    <div>
                      <img src={imagePath.image} alt={imagePath.title} />
                      <h2>{imagePath.title}</h2>
                      <p>{imagePath.description}</p>
                    </div>
                  </motion.div>
                   )}
               
                 </motion.div>
                 
               );
             })}
            <div className='bambu_tm-topbar-button-container'>
                    <button onClick={handleCloseClick} className="bambu_tm_btn">Back</button>
                    </div>
        
           </div>
       );
       
           
   }
   
   export default IndustryCard;
