import React, { useState } from 'react';
import EditorHeader from './EditorHeader';
import ImagePlaceholder from './ImagePlaceholder';
import EditorContent from './EditorContent';
import EditorButtons from './EditorButtons';
import { useDrop } from 'react-dnd';

function EmailEditor() {
    const [content, setContent] = useState([]);

    const [, ref] = useDrop({
        accept: 'ICON',
        drop: (item, monitor) => {
            if (item.iconType) {
                // Update content based on dropped item
                setContent(prevContent => [...prevContent, item.iconType]);
            }
        }
    });

    return (
        <div className="emailEditor" ref={ref}>
            <EditorHeader />
            <ImagePlaceholder />
            <EditorButtons />
        </div>
    );
}

export default EmailEditor;
