// ./components/Retail/RetailDemoSystem.js

import React from 'react';
import Sidebar from './SideBar';
import EmailEditor from './EmailEditor';
//import DraggableIcon from './DraggableIcon';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

function RetailDemoSystem() {
    return (
        <DndProvider backend={HTML5Backend}>
            <div className="retailDemoSystem">
                <Sidebar />
                <EmailEditor />
            </div>
        </DndProvider>
    );
}

export default RetailDemoSystem;
