import React, { useState } from 'react';
import ModalIdCard from './ModalIdCard';
function EmployeeBadgeRequest() {
    const [isModalOpen, setModalOpen] = useState(false);
    const [qrCodeURL, setQRCodeURL] = useState(null);
    const [numberOfCards, setNumberOfCards] = useState(0); // State to hold the number of cards

    const handleNumberOfCardsChange = (count) => {
        setNumberOfCards(count);
    };

    return (
        <div className="employeeBadgeSystem">
            <main className="main">
                <div className="hero-section">
                    <h1>Welcome to the Employee Badge System</h1>
                    <p>Click below to request your employee badge.</p>
                    <button 
                        className="btn request-badge-btn" 
                        onClick={() => setModalOpen(true)}
                    >
                        Request Badge
                    </button>
                </div>
                <ModalIdCard 
                    isOpen={isModalOpen} 
                    onClose={() => setModalOpen(false)} 
                    setQRCodeURL={setQRCodeURL} 
                    onNumberOfCardsChange={handleNumberOfCardsChange} // Pass this callback to the modal
                />
            </main>
        </div>
    );
}

export default EmployeeBadgeRequest;
