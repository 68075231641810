/* global ApplePaySession */

import React, { useState } from 'react';

function ApplePayWithLoyalty() {
    const [useLoyalty, setUseLoyalty] = useState(false);
    const [transactionStatus, setTransactionStatus] = useState('');
    const [remainingPoints, setRemainingPoints] = useState(null);

    const handleApplePayClick = async () => {
        if (!window.ApplePaySession || !ApplePaySession.canMakePayments()) {
            setTransactionStatus('Apple Pay is not available on this device.');
            return;
        }

        const paymentRequest = {
            countryCode: 'US',
            currencyCode: 'USD',
            supportedNetworks: ['visa', 'masterCard', 'amex', 'discover'],
            merchantCapabilities: ['supports3DS'],
            total: {
                label: 'Your Merchant Name',
                amount: '10.00' // Example amount
            }
        };

        const session = new ApplePaySession(1, paymentRequest);

        session.onvalidatemerchant = async (event) => {
            try {
                const response = await fetch('/your-backend-route-to-get-session', {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({ validationURL: event.validationURL })
                });
                const sessionData = await response.json();
                session.completeMerchantValidation(sessionData);
            } catch (error) {
                console.error('Error validating merchant:', error);
                setTransactionStatus('Error during Apple Pay validation.');
            }
        };

        session.onpaymentauthorized = async (event) => {
            try {
                const response = await fetch('/api/applepay-with-loyalty', {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({
                        token: event.payment.token,
                        useLoyalty: useLoyalty,
                        userID: 'userID123' // Example user ID
                    })
                });
                const result = await response.json();
                setTransactionStatus(result.status);
                if (result.loyaltyBenefitApplied) {
                    setRemainingPoints(result.remainingPoints);
                }
                session.completePayment(ApplePaySession.STATUS_SUCCESS);
            } catch (error) {
                console.error('Error processing payment:', error);
                session.completePayment(ApplePaySession.STATUS_FAILURE);
                setTransactionStatus('Error during payment processing.');
            }
        };

        session.begin();
    };

    return (
        <div>
            <h2>Checkout</h2>
            <div>
                <input 
                    type="checkbox"
                    checked={useLoyalty}
                    onChange={() => setUseLoyalty(!useLoyalty)}
                />
                <label>Use Loyalty Card</label>
            </div>
            <button onClick={handleApplePayClick}>Pay with Apple Pay</button>
            {transactionStatus && <p>{transactionStatus}</p>}
            {remainingPoints !== null && <p>Loyalty Points Remaining: {remainingPoints}</p>}
        </div>
    );
}

export default ApplePayWithLoyalty;
