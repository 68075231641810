import React, { useEffect, useState } from 'react';

const Preloader = () => {
  const [isPreloaded, setIsPreloaded] = useState(false);
  
  useEffect(() => {
    const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry/i.test(navigator.userAgent) ? true : false;

    if (!isMobile) {
      setTimeout(() => {
        setIsPreloaded(true);
      }, 800);

      setTimeout(() => {
        const preloader = document.getElementById('preloader');
        if (preloader) {
          preloader.remove();
        }
      }, 2000);

    } else {
      const preloader = document.getElementById('preloader');
      if (preloader) {
        preloader.remove();
      }
    }
  }, []);
  
  return (
    <div id="preloader" className={isPreloaded ? 'preloaded' : ''}>
      {/* Preloader content here */}
    </div>
  );
};

export default Preloader;
