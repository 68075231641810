import React, { useState } from 'react';
import lunchSpecial from '../../shared/img/lunchSpecial.jpeg';
import beepSound from '../../shared/img/beep-02.mp3';
import Cookies from 'js-cookie';
const Engagement = ({onClose}) => {
    const tilesData = [
        { id: 1, imgSrc: lunchSpecial, title: "First Lunch", templateTierId: "131",points: "100" },
        { id: 2, imgSrc: lunchSpecial, title: "Second Lunch", templateTierId: "132",points: "200" },
        { id: 3, imgSrc: lunchSpecial, title: "Third Lunch", templateTierId: "133",points: "300" },
        { id: 4, imgSrc: lunchSpecial, title: "Free Lunch", templateTierId: "134",points: "400"}
    ];
    const [activeTiles, setActiveTiles] = useState([true, true, true, true]);
    const [isScanning, setIsScanning] = useState(false);
    const [allScanned, setAllScanned] = useState(false);
    const [scanningTiles, setScanningTiles] = useState(Array(tilesData.length).fill(false));



    const handleScanClick = async (index, templateTierId, points) => {
        // Create a copy of the scanningTiles state and set the scanning state for the clicked tile
        const newScanningTiles = [...scanningTiles];
        
        newScanningTiles[index] = true;
        setScanningTiles(newScanningTiles);
        
        setTimeout(async () => {
            // After the timeout (e.g., 2 seconds), set the scanning state for the clicked tile back to false
            newScanningTiles[index] = false;
            setScanningTiles(newScanningTiles);
            
            const email = Cookies.get('userEmail');
            const requestBody = {
                brandId: 9,
                programId: 265,
                templateId: 180,
                templateTierId,
                email: email,
                passdata: {
                    points: points  // replace with the hardcoded value
                }
                //currentMessage: templateTierId  // Assuming templateTierId serves as the currentMessage in this example
            };
    
            try {
                const response = await fetch('/updateCard', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(requestBody),
                });
    
                const responseData = await response.json();
    
                if (!response.ok) {
                    throw new Error(responseData.error);
                }
    
                console.log('Card Updated successfully:', responseData);
                
        
                // Deactivate the tile upon a successful update
                const newActiveTiles = [...activeTiles];
                newActiveTiles[index] = false;
                setActiveTiles(newActiveTiles);
                
                // Optionally, check if all tiles are deactivated
                if (newActiveTiles.every(tile => !tile)) {
                    setAllScanned(true);
                }

            } catch (error) {
                console.error('Error updating card:', error.message);
                alert('Error updating card. Please try again.');
            }
        }, 2000);
    };
   
    return (
        <div className="bambu_tm_template-container">
            <button className="bambu_tm_close-button" onClick={onClose}>X</button>
            
    
            {/* Tiles */}
            {!allScanned ? (
                <>
                <h2>Promotions</h2>
                <div className="tiles-container"> {/* New container for tiles */}
                {tilesData.map((tile, index) => (
                <div key={tile.id} className={`tile ${activeTiles[index] ? 'active' : 'inactive'}`}>
                    <div style={{ marginBottom: '10px' }}>{tile.title}</div>
                    <div className={`scanner-overlay ${isScanning ? 'visible' : 'hidden'}`}></div>
                    <img src={tile.imgSrc} alt={`Tile ${index + 1}`} style={{ marginBottom: '10px' }} />
                    <audio className="scanner-beep" src={beepSound}></audio>
                    <div>{activeTiles[index] && 
                        <button className='bambu_tm_btn' onClick={() => {
                            document.querySelector(".scanner-beep").play();
                            handleScanClick(index,tile.templateTierId, tile.points);
                        }}>
                            Scan
                        </button>}
                    </div>
                    {scanningTiles[index] && <div className="scan-status">Scanning...</div>} {/* This line here */}
                </div>
            ))}
            </div>
                </>
            ) : (
                <div className="awarded-message">
                <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSiEcYJzWK38JJoq6dUnj2OWxJHH60pZrtmBw&usqp=CAU" alt="ree Lunch Awarded" style={{ marginBottom: '20px' }} />
                Free Lunch has been awarded, check your wallet card.
                </div>
            )}
        </div>
    );
    
}    
export default Engagement;
