import React from 'react';

// Constants (could be moved to a separate constants file)
const COMPANY_NAME = "BambuMeta";
const CURRENT_YEAR = new Date().getFullYear();

function Disclaimer() {
    return (
        <p>
            Any actions performed here are for demonstration purposes only and will not have real-world implications. Please read our <a href="/terms-and-conditions">terms and conditions</a> for more details.
        </p>
    );
}

function Copyright() {
    return (
        <p>&copy; {CURRENT_YEAR} {COMPANY_NAME}. All rights reserved.</p>
    );
}

function Footer() {
    return (
        <footer className="footer" aria-label="Main Footer">
            <Disclaimer />
            <Copyright />
        </footer>
    );
}

export default Footer;
