import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import { HeroModalContext } from '../context/heroModal';

function Navbar() {
    const { setIsHeroModalOpen, setHeroModalComponent } = useContext(HeroModalContext);
    const [isDropdownOpen, setIsDropdownOpen] = React.useState(false);
    const [isMobileMenuOpen, setIsMobileMenuOpen] = React.useState(false);

    const navbarItems = [
       // { name: "Get your card", link: "#", modalComponent: "GetYourCard" },
        { name: "Start Here", modalComponentId: "activationForm" },
      //  { name: "Industry", modalComponentId: "industryCards" },
    ];

    const handleLinkClick = modalComponentId => {
        console.log("Clicked", modalComponentId);
        setHeroModalComponent(modalComponentId);
        setIsHeroModalOpen(true);
        
    };

    return (
        <div className="bambu_tm_topbar loaded">
            <div className="topbar_inner">
                <div className="logo">
                    <Link to="/">
                        <img src="https://images.squarespace-cdn.com/content/v1/63ff448f8cb22765843dcff0/a793a91b-bb79-4b39-b3c5-8fb997c068f3/LOGO+bluegreen.png?format=1500w" alt="logo" />
                    </Link>
                </div>
                <button 
                    className="bambu_tm_trigger hamburger" 
                    onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
                >
                    <FontAwesomeIcon icon={faBars} />
                </button>

                <div className="menu">
                    <ul>
                        <li onMouseEnter={() => setIsDropdownOpen(true)} onMouseLeave={() => setIsDropdownOpen(false)}>
                        <Link to="#" style={{ backgroundColor: 'green', padding: '5px 10px', color: 'white' }}>Try it yourself</Link>

                            {isDropdownOpen && (
                                <ul className="dropdown-menu">
                                    {navbarItems.map((item, index) => (
                                        <li key={index}>
                                            <Link 
                                                to={item.link} 
                                                onClick={(e) => {
                                                    if (item.modalComponentId) {
                                                      e.preventDefault();
                                                      e.stopPropagation(); 
                                                      handleLinkClick(item.modalComponentId);
                                                    }
                                                }}
                                            >
                                                {item.name}
                                            </Link>
                                        </li>
                                    ))}
                                </ul>
                            )}
                        </li>
                        <li className="current">
                            <Link to="/">Home</Link>
                        </li>
                        <li>
                            <a href="https://app.bambumeta.software">Platform</a>
                        </li>
                        <li>
                            <a href="/contact">Contact</a>
                        </li>
                    </ul>
                </div>

                <div className={`bambu_tm_mobile_menu ${isMobileMenuOpen ? 'active' : ''}`}>
                    <ul>
                        {/* ... (rest of the mobile menu items) ... */}
                    </ul>
                </div>
            </div>
        </div>
    );
}

export default Navbar;
