import React, { useState } from 'react';
import Cookies from 'js-cookie';
import QRCode from 'qrcode.react';

const SERVER_URL = process.env.SERVER_URL || "http://localhost:3000";

function Modal({ isOpen, onClose, onSubmit }) {
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [policyNumber, setPolicyNumber] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [pkPassesQRCode, setPkPassesQRCode] = useState(null);

    async function handleFormSubmit() {
        setIsLoading(true);
        try {
            const token = await fetch(`${SERVER_URL}/getPandaToken`).then(res => res.json()).then(data => data.token);
            Cookies.set('accessToken', token);
    
            const cardResponse = await fetch(`${SERVER_URL}/createCard`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify({ firstName, lastName, email }),
            });
    
            const cardData = await cardResponse.json();
    
            // Log the entire server response
            console.log("Server response:", cardData);
    
            if (cardData && cardData.downloadUrl) {
                setPkPassesQRCode(cardData.downloadUrl);
                console.log("URL for QR Code:", cardData.downloadUrl);
            } else {
                console.error("Failed to retrieve .pkpasses download URL from the server.");
            }
    
        } catch (error) {
            console.error('Error occurred:', error);
        } finally {
            setIsLoading(false);
        }
    }
    
    if (!isOpen) return null;

    return (
        <div className="modal-overlay">
            <div className="modal">
                <button className="close-button" onClick={onClose}>X</button>
                <div className="input-container">
                    <input value={firstName} onChange={e => setFirstName(e.target.value)} placeholder="First Name" />
                    <input value={lastName} onChange={e => setLastName(e.target.value)} placeholder="Last Name" />
                    <input type="email" value={email} onChange={e => setEmail(e.target.value)} placeholder="Email" />
                    <input value={policyNumber} onChange={e => setPolicyNumber(e.target.value)} placeholder="Policy Number" />
                </div>
                {pkPassesQRCode ? (
                    <div>
                        <QRCode value={pkPassesQRCode} />
                        <p>Scan the QR Code to download the pkpasses file</p>
                    </div>
                ) : isLoading ? (
                    <div className="spinner">Loading...</div>
                ) : (
                    <button className="btn" onClick={handleFormSubmit}>Submit</button>
                )}
            </div>
        </div>
    );
}

export default Modal;
