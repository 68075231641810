import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'; // Assuming you have FontAwesome installed.
import { library } from '@fortawesome/fontawesome-svg-core';
import { faEnvelope, faListAlt, faSearch, faTimes, faCheckCircle, faSpinner } from '@fortawesome/free-solid-svg-icons';

library.add(faEnvelope, faListAlt, faSearch, faTimes, faCheckCircle, faSpinner);

function PushNotifications ({onClose})  {
    const [mode, setMode] = useState(null);  // send-message or templates
    const [selectedOption, setSelectedOption] = useState(null);  // User, Bulk or Search
    const [message, setMessage] = useState('');
    const userEmail = document.cookie.replace(/(?:(?:^|.*;\s*)userEmail\s*\=\s*([^;]*).*$)|^.*$/, "$1");
    const [searchValue, setSearchValue] = useState('');
    const [showSearchResults, setShowSearchResults] = useState(false);
    const [filteredResults, setFilteredResults] = useState([]);
    const [selectedSegment, setSelectedSegment] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [showSuccessModal, setShowSuccessModal] = useState(false);

    const handleSearch = () => {
        const results = mockData.filter(item => 
            item.toLowerCase().includes(searchValue.toLowerCase())
        );
        setFilteredResults(results);
    
        // Toggle showing search results
        setShowSearchResults(true);
    };
    
    const mockData = [
        'First Time Visitors',
        'Premium Members',
        'Refer a Friend Member',
        'Regular Members',
        'Guests',
        'Subscribers',
        'New Users'
    ];
    
    const sendNotification = async () => {
        setIsLoading(true);  // Set loading to true to show the loading screen
        try {
            const requestBody = {
                brandId: 9,            // Static values as per your example
                programId: 265,
                templateId: 180,
                currentMessage: message, // Message from the state
                email: userEmail 
            };
            console.log("Sending to backend:", JSON.stringify(requestBody));
            const response = await fetch('/updateCard', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(requestBody),
            });
    
            const responseData = await response.json();
    
            if (!response.ok) {
                throw new Error(responseData.error);
            }
    
            setShowSuccessModal(true);
        } catch (error) {
            setIsLoading(false);
            console.error('Error sending notification:', error.message);
            
        } finally {
            setIsLoading(false);
        }
    };
    
        
    
    const handleSendMessage = () => {
        if (message.trim() !== '') {
            alert('Message sent: ' + message);
            setMessage('');
        }
    };

    return (
        <div className="panel-container">
    
            {/* Left Panel */}
            
            <div className="left-panel">
                <div className="icon-button" onClick={() => setMode('send-message')}>
                    <FontAwesomeIcon icon="envelope" /> <div>&nbsp;</div> Message
                </div>
                <div className="icon-button" onClick={() => setMode('templates')}>
                    <FontAwesomeIcon icon="list-alt" /> <div>&nbsp;</div> Templates
                </div>
            </div>
    
            {isLoading && (
                    <div className="bambu_tm_loading-modal-overlay">
                        <div className="bambu_tm_loading-modal-content">
                            <FontAwesomeIcon icon="spinner" spin /> {/* Assuming FontAwesome's spinner icon */}
                            Sending...
                        </div>
                    </div>
                )}
                {showSuccessModal && (
                    <div className="bambu_tm_success-modal-overlay">
                        <div className="bambu_tm_success-modal-content">
                        
                           <div>&nbsp;</div> Message sent successfully!
                           <FontAwesomeIcon icon="check-circle" style={{ color: 'green' }} />
                        </div>
                    </div>
                )}

            <div className="main-panel">
                {mode === 'send-message' && (
                    <>
                        <div className="bambu_tm_input-fields-container">
                            <select value={selectedOption} onChange={(e) => setSelectedOption(e.target.value)}>
                                <option value={null}>Select Option</option>
                                <option value="User">User</option>
                                <option value="Bulk">Bulk</option>
                                <option value="Search">Search</option>
                            </select>
                            
                            {selectedOption === 'User' && (
                                <>
                                    <div>To: {userEmail}</div>
                                    <label>Message: <textarea value={message} onChange={(e) => setMessage(e.target.value)} /></label>
                                    <button className="bambu_tm_btn" onClick={sendNotification}>Send</button>
                                </>
                            )}
    
                            {selectedOption === 'Bulk' && (
                                <>
                                    <select>
                                        <option>Select Segment</option>
                                        <option>First Time Visitors</option>
                                        <option>Premium Members</option>
                                    </select>
                                    <label>Message: <textarea value={message} onChange={(e) => setMessage(e.target.value)} /></label>
                                    <button className="bambu_tm_btn" onClick={sendNotification}>Send</button>
                                </>
                            )}
    
                            {selectedOption === 'Search' && (
                            <>
                                <div className="bambu_tm_search-wrapper">
                                <div className="bambu_tm_search-container"onClick={e => e.stopPropagation()}>
                                    <input 
                                        type="text" 
                                        placeholder="Search..." 
                                        value={searchValue} 
                                        onChange={(e) => {
                                            setSearchValue(e.target.value);
                                            handleSearch();
                                        }}
                                        className="bambu_tm_search-input"
                                    />
                                    <FontAwesomeIcon icon={faSearch} className="bambu_tm_search-icon" />
                                </div>
                                {showSearchResults && filteredResults.length > 0 && (
                                    <ul className="bambu_tm_search-results">
                                        {filteredResults.map(result => (
                                        <li 
                                            key={result} 
                                            onClick={(e) => {
                                                e.stopPropagation(); // Stop event propagation to parent elements
                                                setSelectedOption(result);
                                                setSelectedSegment(result);
                                                setShowSearchResults(false);
                                            }}
                                        >
                                            {result}
                                        </li>
                                    ))}

                                    </ul>
                                )}
                                {selectedSegment && <div>To: {selectedSegment}</div>}
                            </div>

                                <label>Message: 
                                    <textarea value={message} onChange={(e) => setMessage(e.target.value)} />
                                </label>
                                <button className="bambu_tm_btn" onClick={sendNotification}>Send</button>
                            </>
                        )}

                        </div>
                    </>
                )}
    
                {mode === 'templates' && (
                    <>
                        
                        <div className="bambu_tm_hospitality-fields-container">
                            <div className="template-header">
                                <span className="column-title">Segment</span>
                                <span className="column-type">Message</span>
                                <span className="column-actions">Actions</span>
                            </div>
                            <div className="template-item">
                                <span className="column-title">General</span>
                                <span className="column-type">Happy Hour</span>
                                <button className="action-button">Send</button>
                            </div>
                            <div className="template-item">
                                <span className="column-title">Lunch Special</span>
                                <span className="column-type">Next Week Specials</span>
                                <button className="action-button">Send</button>
                            </div>
                            
                        </div>
                    </>
                )}
            </div>
            <div>
         </div>
        </div>
     
       
    );
                }
    export default PushNotifications;
    

               
