import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
    faParagraph, faBorderAll, faDivide, faImage, faObjectGroup, 
    faIdCard, faVideo, faShareAlt, faPlus, faRectangleTimes, faBars, faCode
} from '@fortawesome/free-solid-svg-icons';
import DraggableIcon from './DraggableIcon';

function Sidebar() {
    return (
        <div className="sidebar">
            <div className="tabsContainer">
                <div className="tabSection  active">Content</div>
                <div className="tabSection">Design</div>
                <div className="tabSection">Comments</div>
            </div>
            <div className="iconsContainer">
                <DraggableIcon icon={faParagraph} label="Text" />
                <DraggableIcon icon={faBorderAll} label="Boxed Text" />
                <DraggableIcon icon={faDivide} label="Divider" />
                <DraggableIcon icon={faImage} label="Image" />
                <DraggableIcon icon={faObjectGroup} label="Image Group" />
                <DraggableIcon icon={faIdCard} label="Image Card" />
                <DraggableIcon icon={faImage} label="Image + Caption" />
                <DraggableIcon icon={faShareAlt} label="Social Share" />
                <DraggableIcon icon={faPlus} label="Social Follow" />
                <DraggableIcon icon={faRectangleTimes} label="Button" />

                <p className="sidebar-note">Need a refresher? Take a quick tour.</p>
            </div>
        </div>
    );
}

export default Sidebar;
