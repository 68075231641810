import React, { useContext } from 'react';
import { useLocation } from 'react-router-dom';
import paperSVG from '../shared/img/paper.svg';
import useBodyClass from '../hooks/useBodyClass';
//import Navbar from './Navbar';
import YouTube from 'react-youtube';
import useContactDetails from '../hooks/useContactDetails';
import ContactDetails from '../components/main/ContactDetails';
import DefaultContent from '../components/main/DefaultContent';
import IndustryCard from '../components/main/IndustryModal';
import ActivationFormModal from './main/ActivationFormModal';
import { HeroModalContext } from '../context/heroModal';
import Footer from './Footer';
function HeroSection() {
  
  //const [isHeroModalOpen, setIsHeroModalOpen] = useState(false);
  //const [heroModalComponent, setheroModalComponent] = useState(null);
  //console.log("HeroSection - isHeroModalOpen:", isHeroModalOpen);
  //console.log("HeroSection - heroModalComponent:", heroModalComponent);
  const { isHeroModalOpen, heroModalComponent, setIsHeroModalOpen, setHeroModalComponent } = useContext(HeroModalContext);

  
//console.log("isHeroModalOpen:", isHeroModalOpen);
//console.log("heroModalComponent", heroModalComponent);
//console.log("setIsHeroModalOpen:", setIsHeroModalOpen);
//console.log("setHeroModalComponent", setHeroModalComponent);

  useBodyClass('loaded');
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const email = params.get('email');
  const company = params.get('company');
  const contactDetails = useContactDetails(email, company);

  const videoOptions = {
    width: '100%',
    height: '100%',
    playerVars: {
      autoplay: 1,
      controls: 0,
      modestbranding: 1,
      rel: 0,
      enablejsapi: 1,
      accelerometer: 1,
      clipboardWrite: 1,
      encryptedMedia: 1,
      gyroscope: 1,
      pictureInPicture: 1,
      mute: 1,
      loop: 1,
    },
  };

  const closeFunc = () => {
    setIsHeroModalOpen(false);
  };

  return (
    <>

        <div className="container">
            <div className="bambu_tm_hero">
                {(company || email || (contactDetails && contactDetails.properties))
                    ? <ContactDetails company={company} contactDetails={contactDetails} />
                    : <DefaultContent />
                }
                <div className="bambu_tm_talk">
                    <div className="background video-container" id="talk"> 
                        <div className="shape">
                            <img className="svg" src={paperSVG} alt="paper illustration" />
                        </div>
                        <div className="background" id="talk">
                            <YouTube className="youtube-component" videoId="l6JN5M12BbE" opts={videoOptions} />
                            <div className="overlay"></div>
                        </div>
                    </div>
                </div> 
            </div> 
        </div>
        
        {isHeroModalOpen && (
            <div className="bambu_tm_modal-overlay">
                <div className="bambu_tm_topbar-modal-content">
                    { heroModalComponent === "activationForm" && <ActivationFormModal setHeroModalComponent={setHeroModalComponent} setIsHeroModalOpen={setIsHeroModalOpen} /> }
                    { heroModalComponent === "industryCards" && <IndustryCard setHeroModalComponent={setHeroModalComponent} setIsHeroModalOpen={setIsHeroModalOpen} /> }
                    {/* ... other modal content if any */}
                </div>
                
            </div>
            
        )}
    </>
);

}

export default HeroSection;
