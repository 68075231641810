import React from 'react';
import PortfolioItem from './PortfolioItem';
import { Link } from 'react-router-dom';

function PortfolioList() {
  // Sample data for your portfolio items
  const items = [
    { category: 'hospitality', title: 'Activation', imageSrc: 'img/activation.png' },
    { category: 'hospitality', title: 'Reservations', imageSrc: 'img/restaurant.png' },
    // ... other items ...
  ];

  return (
    <div className="bambu_tm_portfolio">
    <div className="container">
        {/* Title Section */}
        
        {/* Content of RockTheVote */}
        <div className="portfolio_list">
            <ul className="gallery_zoom">
                <li>
                    <div className="inner">
                    <div className="bambu_tm_title">
                    <div className="title_flex">
                        <div className="left">
                            <span>Public</span>
                            <h3>Rock The Vote</h3>
                        </div>
                    </div>
                    </div>
                    <div className="entry bambu_tm_portfolio_animation_wrap" data-title="RockTheVote">
                    <Link to="/demos/rock-the-vote">  {/* Linking to the RockTheVote page */}
                    <img 
                        src="https://m.media-amazon.com/images/M/MV5BNGQ3ZWY5OTYtMjQxZC00NmYxLTk2ZWQtMDVmYzU0NTRhYmI0XkEyXkFqcGdeQXVyODQ1NTk5OQ@@._V1_.jpg" 
                        alt="Rock The Vote" 
                        className="resizedImage"
                     />
                    </Link>
                    <div className="mobile_title">
                        <h3>Rock The Vote Description</h3>
                        <span>Some additional text or category here</span>
                    </div>
                </div>

                    </div>
                </li>
                <li>
                    <div className="inner">
                    <div className="bambu_tm_title">
                    <div className="title_flex">
                        <div className="left">
                            <span>Hospitality</span>
                            <h3>Restautants</h3>
                        </div>
                    </div>
                    </div>
                    <div className="entry bambu_tm_portfolio_animation_wrap" data-title="RockTheVote">
                    <Link to="/demos/rock-the-vote">  {/* Linking to the RockTheVote page */}
                    <img 
                        src="https://www.naturallygluten-free.com/images/dining_card_front-en.jpg" 
                        alt="Rock The Vote" 
                        className="resizedImage"
                     />
                    </Link>
                    <div className="mobile_title">
                        <h3>Rock The Vote Description</h3>
                        <span>Some additional text or category here</span>
                    </div>
                </div>

                    </div>
                </li>
                {/* You can add more list items here for more components */}
            </ul>
        </div>
    </div>
</div>
  );
}

export default PortfolioList;
