import React, { useState} from 'react';
import ActivationSlide from '../../shared/img/reservations_slide.png';
import LoyaltySlide from '../../shared/img/hospitality_rewards_slide.png';
import MessageSlide from '../../shared/img/hospitality_messaging.png';
import ReservationForm from './reservationForm';
import ActivationForm from './ActivationForm';
import PushNotifications from './PushNotifications';
import Engagement from './Engagement';
import Location from './LocationAlerts';
//import QRCodeDisplay from './QRCodeDisplay';  // Adjust the path if needed
//import AppleWalletSVG from '../../shared/img/US-UK_Add_to_Apple_Wallet_RGB_101421.svg';
//import GooglePaySVG from '../../shared/img/Save to Google Pay.svg';

function UseCasesModal({ isOpen, onClose, useCaseTitle }) {
    const [showActivationForm, setShowActivationForm] = useState(false);
   // const [qrCodeURL, setQRCodeURL] = useState('');
    const [isLoading] = useState(false);
    const [error] = useState('');
    const [showReservationForm, setShowReservationForm] = useState(false);
    const [showPushNotifications, setShowPushNotifications] = useState(false);
    const [showEngagement, setShowEngagement] = useState(false);
    const [showLocation, setShowLocation] = useState(false);
   // const [isMobile, setIsMobile] = useState(false);
   // const [downloadUrl, setDownloadUrl] = useState('');
    //const WALLET_BASE_URL = 'https://wallet-pass-sandbox.bambumeta.software'
    //const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
    //const isAndroid = /Android/.test(navigator.userAgent);
   // const walletSVG = isIOS ? AppleWalletSVG : isAndroid ? GooglePaySVG : null;
   // useEffect(() => {
        // Check if the device is mobile
   //     const checkMobile = () => {
  //          const mobileView = window.innerWidth <= 768;
   //         setIsMobile(mobileView);
    //    };

  //      checkMobile(); // initial check
  //      window.addEventListener('resize', checkMobile); // add listener to detect window resize
//
 //       return () => {
//            window.removeEventListener('resize', checkMobile); // cleanup listener on component unmount
//        }
 //   }, []);
    
   // const handleActivationForm = () => {
    //    setActivationForm(true);
    //};
    

    //const handleReservationSubmit = (reservationData) => {
     //   console.log(reservationData);

    //    setShowReservationForm(false);
   // };

    //const handleQRCodeGenerated = (qrCodeURL, downloadUrl) => {
    //    setQRCodeURL(qrCodeURL);
     //   setDownloadUrl(downloadUrl);
   //}
    
    const modalUseCases = [
        { 
            title: "Activation", 
            description: "Dive into the future of dining with our innovative wallet card activation system! We understand the need for restaurants to swiftly and efficiently connect with their patrons. That's why we've crafted a solution that allows eateries to empower their customers with wallet card activations in mere days, not dragging months. With our cost-effective approach, restaurants can effortlessly enhance their customer experience, ensuring loyalty and streamlined transactions. Step into the fast lane of digital dining and watch your customer base grow exponentially!", 
            image: ActivationSlide,
            additionalContent: () => (
                <button className="bambu_tm_btn" onClick={() => setShowActivationForm(true)}>Get your card</button>
            )
        },
        { 
            title: "Reservations", 
            description: "Detailed description for Use Case 2", 
            image: ActivationSlide,
            additionalContent: () => (
                <button className="bambu_tm_btn" onClick={() => setShowReservationForm(true)}>Make Reservation</button>
            )
        },
        { 
            title: "Engagement", 
            description: "Detailed description for Use Case 2", 
            image: LoyaltySlide,
            additionalContent: () => (
                <button className="bambu_tm_btn" onClick={() => setShowEngagement(true)}>Start Engaging</button>
            )
        },
        { 
            title: "Messaging", 
            description: "Detailed description for Use Case 2", 
            image: MessageSlide,
            additionalContent: () => (
                <button className="bambu_tm_btn" onClick={() => setShowPushNotifications(true)}>Start Communicating</button>
            )
        },
        { 
            title: "Geo-Location", 
            description: "Detailed description for Use Case 2", 
            image: MessageSlide,
            additionalContent: () => (
                <button className="bambu_tm_btn" onClick={() => setShowLocation(true)}>Target your customers</button>
            )
        },
        // ... Add other use cases as needed
    ];
    
    const selectedUseCase = modalUseCases.find(uc => uc.title === useCaseTitle);

    if (!isOpen || !selectedUseCase) return null;

    return (
        <div className="modal-wrapper">
            <div className="modal-overlay">
                <div className="bambu_tm_modal">
                    {isLoading ? (
                        <div className='bambu_tm_loading-modal-content'>
                      <video width="320" height="240" autoPlay loop muted>
                        <source src="../../shared/img/loading.mp4" type="video/mp4" />
                        Your browser does not support the video tag.
                    </video>
                    </div>
                    ) : error ? (
                        <div>
                            <h2>Error</h2>
                            <p>{error}</p>
                        </div>
                    
                    ) : showActivationForm && selectedUseCase.title === "Activation" ? (
                        <ActivationForm onSubmit={setShowLocation} onClose={onClose} />
                    ) : showReservationForm && selectedUseCase.title === "Reservations" ? (
                        <ReservationForm onSubmit={setShowReservationForm} onClose={onClose} />
                    ) : showPushNotifications && selectedUseCase.title === "Messaging" ? (
                        <PushNotifications onSubmit={setShowPushNotifications} onClose={onClose} />
                    ) : showEngagement && selectedUseCase.title === "Engagement" ? (
                        <Engagement onSubmit={setShowEngagement} onClose={onClose} />
                    ) : showLocation && selectedUseCase.title === "Geo-Location" ? (
                        <Location onSubmit={setShowLocation} onClose={onClose} />
                    ) : (
                        <div className='bambu_tm_modal-content'>
                            <button className="bambu_tm_close-button" onClick={onClose}>X</button>
                        
                            <img src={selectedUseCase.image} alt={selectedUseCase.title} className="use-case-image" />
                            <p>{selectedUseCase.description}</p>
                            {selectedUseCase.additionalContent && selectedUseCase.additionalContent()}
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
    
}

export default UseCasesModal;
