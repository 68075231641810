import React from 'react';
import { useLocation, Outlet } from 'react-router-dom'; // <-- Import useLocation here
//import RockTheVote from './RockTheVote/RockTheVote';
import PortfolioList from './PortfolioList';

function Demos() {
  const location = useLocation();  // <-- Use the hook inside the component
  console.log(location.pathname);  // <-- This will log the current route to the console

  return (
    <div>
      
      <PortfolioList />
      {/* This Outlet component will act as a placeholder for rendering 
           nested components like RockTheVote, IdCard, etc. */}
      <Outlet /> 
      
      {/* Add links to other demos as needed */}
    </div>
  );
}

export default Demos;
