const DefaultContent = () => {
    return (
        <div className="content">
            <div className="name_wrap">
                <h3><span>DIGITAL ENGAGEMENT,</span><div>&nbsp;</div>REDEFINED<span className="overlay_effect"></span></h3>
            </div>
            <p className="bambu_tm_description">Reach your customers with highly engaging wallet passes through BambuMeta’s new Wallet Pass Engagement Platform. 
            Deliver customizable passes and open a new communication channel for your business through native Apple Wallet and Google Wallet passes. </p>
            <div className="bambu_tm_hero-btn-container">
            <button className="bambu_tm_btn"> Learn More</button>
            </div>
    </div>
    );
};

export default DefaultContent;
