import React from 'react';

const UserDetailsForm = ({ formData, handleChange, handleFormSubmit, closeFunc }) => {
    return (
        <div className='bambu_tm_topbar-modal-content'>
            <div className="bambu_tm_input-fields-container">
                <form onSubmit={handleFormSubmit}>
                    <h2>Tell us about yourself</h2>
                    {/* Map over the formData to generate input fields */}
                    {Object.keys(formData).map((key) => (
                        <input 
                            key={key}
                            id={key}
                            name={key}
                            type="text" 
                            placeholder={key.charAt(0).toUpperCase() + key.slice(1)} 
                            onChange={handleChange} 
                        />
                    ))}
                    <div className='bambu_tm-topbar-button-container'>
                        <button type="button" className='bambu_tm_btn' onClick={closeFunc}>Close</button>
                        <button type="submit" className='bambu_tm_btn-green'>Get your card</button>
                    </div>
                </form>
            </div>
        </div>
    );
}

export default UserDetailsForm;
