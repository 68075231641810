import React, { useState } from 'react';
import './idcard.css';
import Cookies from 'js-cookie';

const departmentPrefixes = {
  "Information Technology": "IT",
  "Human Resources": "HR",
  "Finance": "FI",
  "Vendor/Contractor": "VN"
};

const generateEmployeeId = (department) => {
  const prefix = departmentPrefixes[department] || department.charAt(0).toUpperCase();
  const randomNumber = Math.floor(10000 + Math.random() * 90000);
  return `${prefix}${randomNumber}`;
};

const templateIdTiers = {
  employee: 1,
  sales: 156,
  doorAccess: null,
  contractor: null
};

function ModalIdCard({ isOpen, onClose }) {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [department, setDepartment] = useState('');
  const [position, setPosition] = useState('');
  const [imageBase64, setImageBase64] = useState('');
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [qrCodeURL, setQRCodeURL] = useState(null);
  const [isEmployee, setIsEmployee] = useState(false);
  const [isSales, setIsSales] = useState(false);
  const [hasDoorAccess, setHasDoorAccess] = useState(false);
  const [isContractor, setIsContractor] = useState(false);

  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    if (!file) return;

    const reader = new FileReader();
    reader.onloadend = () => {
      const img = new Image();
      img.src = reader.result;
      img.onload = () => {
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
        canvas.width = 90;
        canvas.height = 90;
        ctx.drawImage(img, 0, 0, 90, 90);
        const resizedImageBase64 = canvas.toDataURL("image/png");
        setImageBase64(resizedImageBase64);
      };
    };
    reader.readAsDataURL(file);
  };
  const getTemplateTierIds = () => {
    
    const ids = [];
    console.log("Selected templatetierIds:", ids);

    console.log('Before Sales push:', ids);
    if (isSales && templateIdTiers.sales) ids.push(templateIdTiers.sales);
    console.log('After Sales push:', ids);

    console.log('Before Employee push:', ids);
    if (isEmployee && templateIdTiers.employee) ids.push(templateIdTiers.employee);
    console.log('After Employee push:', ids);

    if (hasDoorAccess && templateIdTiers.doorAccess) ids.push(templateIdTiers.doorAccess);
    if (isContractor && templateIdTiers.contractor) ids.push(templateIdTiers.contractor);

    return ids;
};
  

  const handleFormSubmit = async () => {
    setIsLoading(true);

    const templateIds = getTemplateTierIds();
    const generatedEmployeeId = generateEmployeeId(department);

    try {
        const apiBaseUrl = process.env.API_BASE_URL || 'http://localhost:3000';
        let token = Cookies.get('accessToken');
    
        if (!firstName || !lastName || !email || !department || !position || !imageBase64) {
            throw new Error('Please fill in all required fields and upload an image.');
        }
        
        const cardData = {
            accessToken: token,
            brandId: 9,
            programId: 351,
            templateId: 249,
            templatetierId: templateIds,  // Modified this line
            person: {
                firstName,
                lastName,
                email,
                phone: ''
            },
            passdata: {
                profileImage: imageBase64,
                metaData: {
                    employeedept: department,
                    employeedateIssued: "01/01/2023",
                    empoyeedateExired: "12/31/2023",
                    employeeno: generatedEmployeeId,
                    employeetitle: `${firstName} ${lastName}`,
                },
            },
        };
        const createCardResponse = await fetch(`${apiBaseUrl}/createCard`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(cardData)
        });
    
        const responseData = await createCardResponse.json();
    
        if (responseData && responseData.qrCodeURL) {
            setQRCodeURL(responseData.qrCodeURL);
        } else {
            throw new Error("QR Code URL not found in response data.");
        }
    
    } catch (error) {
        console.error('Error occurred:', error);
        setError(error.message || 'Failed to create card. Please check your input and try again.');
    }
    
    setIsLoading(false);
  };    

  const resetModalState = () => {
    setFirstName('');
    setLastName('');
    setEmail('');
    setDepartment('');
    setPosition('');
    setImageBase64('');
    setError(null);
    setIsLoading(false);
    setQRCodeURL(null);
  };

  const handleClose = () => {
    resetModalState();
    onClose();
  };

  if (!isOpen) return null;

  return (
    <div className="modal-overlay">
        <div className="modal">
            { isLoading ? 
                <div className='bambu_tm_loading-modal-content'>
                <video width="320" height="240" autoPlay loop muted>
                    <source src="../../shared/img/loading.mp4" type="video/mp4" />
                    Your browser does not support the video tag.
                </video>
                </div>
                : 
                qrCodeURL ? 
                <div>
                    <button className="close-button" onClick={handleClose}>X</button>
                    <h2>Your Employee Badge</h2>
                    <img src={qrCodeURL} alt="QR Code for Employee Badge" />
                    <p>Scan QR code to get your employee badge.</p>
                </div>
                :
                <div>
                    <h2>Employee Badge Form</h2>
                    <button className="close-button" onClick={handleClose}>X</button>

                    <div className="form-container">
                        <div className="input-fields-container">
                            <input value={firstName} onChange={e => setFirstName(e.target.value)} placeholder="First Name" />
                            <input value={lastName} onChange={e => setLastName(e.target.value)} placeholder="Last Name" />
                            <input
                                type="email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                placeholder="Email"
                                required
                            />
                            <select value={department} onChange={e => setDepartment(e.target.value)}>
                                <option value="">Select Department</option>
                                <option value="Information Technology">Information Technology</option>
                                <option value="Human Resources">Human Resources</option>
                                <option value="Finance">Finance</option>
                                <option value="Vendor/Contractor">Vendor/Contractor</option>
                            </select>
                            <input value={position} onChange={e => setPosition(e.target.value)} placeholder="Position" />
                            <div className="file-input-wrapper">
                                <label className="custom-file-upload">
                                    <input type="file" onChange={handleImageUpload} style={{ display: 'none' }} />
                                    Upload Profile Image
                                </label>
                            </div>
                        </div>

                        <div className="checkbox-container">
                            <label>
                            <input 
                                type="checkbox" 
                                checked={isEmployee}
                                onChange={() => {
                                    setIsEmployee(prevState => !prevState);
                                    console.log("Employee Checkbox:", !isEmployee);  // Log the current value
                                }}
                            />
                                Employee
                            </label>

                            <label>
                            <input 
                                type="checkbox" 
                                checked={isSales}
                                onChange={() => {
                                    setIsSales(prevState => !prevState);
                                    console.log("Sales Checkbox:", !isSales);  // Log the current value
                                }}
                            />
                                Sales
                            </label>

                            <label>
                                <input 
                                    type="checkbox" 
                                    checked={hasDoorAccess}
                                    onChange={() => setHasDoorAccess(prevState => !prevState)}
                                />
                                Door Access
                            </label>

                            <label>
                                <input 
                                    type="checkbox" 
                                    checked={isContractor}
                                    onChange={() => setIsContractor(prevState => !prevState)}
                                />
                                Contractor
                            </label>
                        </div>
                    </div>
                    
                    {error && <p className="error-message">{error}</p>}
                    <button className="btn" onClick={handleFormSubmit}>Submit</button>
                </div>
            }
        </div>
    </div>
);

        }

export default ModalIdCard;
