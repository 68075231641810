import React from 'react';
import loadingVideo from '../../shared/img/loading.mp4';

function LoadingModal() {
    return (
        <div className='bambu_tm_video-container'>
            <video width="320" height="240" autoPlay loop muted>
                <source src={loadingVideo} type="video/mp4" />
                Your browser does not support the video tag.
            </video>
        </div>
    );
}

export default LoadingModal;
