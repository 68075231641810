import React, { useState } from 'react';
import { useDrop } from 'react-dnd';

function DropTarget({ area, handleDrop }) {
    const [, ref] = useDrop({
        accept: 'ICON',
        drop: (item, monitor) => handleDrop(item, area)
    });

    return <div ref={ref} className={`bambu_tm_retail-dropTarget bambu_tm_retail-dropTarget-${area}`}></div>;
}

function ImagePlaceholder() {
    const [headerContent, setHeaderContent] = useState('Header (click to edit)');
    const [bodyContent, setBodyContent] = useState('Body (click to edit)');
    const [isEditingHeader, setIsEditingHeader] = useState(false);
    const [isEditingBody, setIsEditingBody] = useState(false);
    const emailUserValue = getCookie('userEmail');
    const [isButtonDropped, setIsButtonDropped] = useState(false);
    const [buttonDetails, setButtonDetails] = useState({
        url: '',
        buttonText: '',
        firstName: '',
        lastName: ''
    });
    function getCookie(name) {
        const value = `; ${document.cookie}`;
        const parts = value.split(`; ${name}=`);
        if (parts.length === 2) return parts.pop().split(';').shift();
    }
    const handleButtonDrop = (item, area) => {
        if (item.iconType === "Button") {
            setIsButtonDropped(true);
        }
        if (area === "header") {
            setHeaderContent(item.iconType);
            setIsEditingHeader(true);
        } else if (area === "body") {
            setBodyContent(item.iconType);
            setIsEditingBody(true);
        }
    };

    const saveButtonDetails = () => {
        // Handle save logic here if needed
        setIsButtonDropped(false);
    };
    return (
        <div className="bambu_tm_retail-imagePlaceholder">
            <div className="emailRecipient">
                To: {emailUserValue}
            </div>
            <div className="emailRecipient">
                From: test@bambueta.com
            </div>
            <div className={`bambu_tm_retail-email-droparea content-header`} onClick={() => setIsEditingHeader(true)}>
                {isEditingHeader ? (
                    <input 
                        type="text" 
                        value={headerContent} 
                        onChange={(e) => setHeaderContent(e.target.value)} 
                        onBlur={() => setIsEditingHeader(false)}
                    />
                ) : (
                    <span>{headerContent}</span>
                )}
            </div>
            <div className={`bambu_tm_retail-email-droparea content-body`} onClick={() => setIsEditingBody(true)}>
                {isEditingBody ? (
                    <textarea
                        rows="5" 
                        cols="30" 
                        value={bodyContent} 
                        onChange={(e) => setBodyContent(e.target.value)} 
                        onBlur={() => setIsEditingBody(false)}
                    />
                ) : (
                    bodyContent
                )}
            </div>
            {isButtonDropped ? (
                <div className="buttonDetailsForm">
                    <input 
                        type="text" 
                        placeholder="URL" 
                        value={buttonDetails.url}
                        onChange={(e) => setButtonDetails(prev => ({ ...prev, url: e.target.value }))}
                    />
                    <input 
                        type="text" 
                        placeholder="Button Text"
                        value={buttonDetails.buttonText}
                        onChange={(e) => setButtonDetails(prev => ({ ...prev, buttonText: e.target.value }))}
                    />
                    <input 
                        type="text" 
                        placeholder="First Name"
                        value={buttonDetails.firstName}
                        onChange={(e) => setButtonDetails(prev => ({ ...prev, firstName: e.target.value }))}
                    />
                    <input 
                        type="text" 
                        placeholder="Last Name"
                        value={buttonDetails.lastName}
                        onChange={(e) => setButtonDetails(prev => ({ ...prev, lastName: e.target.value }))}
                    />
                    <button onClick={saveButtonDetails}>Save</button>
                </div>
            ) : (
                <DropTarget area="button" handleDrop={handleButtonDrop} />
            )}

            {buttonDetails.buttonText && <button>{buttonDetails.buttonText}</button>}
        </div>
    );
}

export default ImagePlaceholder;
