import React from 'react';

function EditorHeader() {
    return (
        <div>
            <h2 className="emailEditorHeader">Sample Email</h2>
            <p className="emailEditorSubheader">It's time to design your email.</p>
        </div>
    );
}

export default EditorHeader;
