import React, { useContext } from 'react';
import QRCode from 'qrcode.react';
import { HeroModalContext } from '../../context/heroModal';
//import IndustryCard from './IndustryModal';

const QRCodeDisplay = ({ qrCodeURL, downloadUrl, closeFunc }) => {
    // Use the useContext hook to get the context values
    const {
        setIsHeroModalOpen, 
        setHeroModalComponent
    } = useContext(HeroModalContext);

    // Additional device checks and logic (if any) can be placed here

    return (
        <div className='bambu_tm_topbar-modal-content'>
            <div className="bambu_tm_input-fields-container">
                <h2>Scan me!</h2>
                <h4>Do not forget to click the add button on the top right hand corner on your phone.</h4>
                <div className="qr-code-container">
                    <QRCode value={downloadUrl} />
                </div>
                <div className='bambu_tm-topbar-button-container'>
                    <button type="button" className='bambu_tm_btn' onClick={closeFunc}>Close</button>
                    <button 
                        type="submit" 
                        className='bambu_tm_btn'
                        onClick={(e) => {
                            e.preventDefault();
                            setHeroModalComponent("industryCards");
                            setIsHeroModalOpen(true);
                        }}
                    >
                        Try our use cases
                    </button>
                </div>
            </div>
        </div>
    );
}

export default QRCodeDisplay;
