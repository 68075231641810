import React, { useState } from 'react';
import Modal from './modal';
import './InsuranceBundle.css';  // Ensure this CSS contains the styles you want

function InsuranceBundle() {
    const [isModalOpen, setModalOpen] = useState(false);
    
    const handleOpenFormClick = () => {
        setModalOpen(true);
    };

    return (
        <div className="insurance-bundle-container">
            <main className="bundle-main">
                <div className="hero-section">
                    <div className="insurance-hero">
                        {/* Replace the following URL with a relevant image for health insurance */}
                        <img src="https://www.customhealthplans.com/wp-content/uploads/2021/04/Best-Health-Insurance-in-Texas-e1653068829292.jpg" alt="Health Insurance Hero" className="hero-bg" />
                        
                        <div className="info-section">
                            <h2>Health Insurance Open Enrollment</h2>
                            <p>Sign up today to get the best rates and coverage for you and your family.</p>
                            <div className="btn-container">
                                <button className="btn open-form-btn" onClick={handleOpenFormClick}>Sign Up Now</button>
                            </div>
                        </div>
                    </div>
                </div>

                <Modal 
                    isOpen={isModalOpen} 
                    onClose={() => setModalOpen(false)} 
                    onSubmit={formData => {
                        console.log(formData);  // Handle the form submission logic here.
                        setModalOpen(false);
                    }}
                />
            </main>
        </div>
    );
}

export default InsuranceBundle;
