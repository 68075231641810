import React, { useState } from 'react';
async function notifySlackBackend(message) {
    const response = await fetch('/api/notifySlack', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({ message })
    });

    if (!response.ok) {
        throw new Error('Failed to notify Slack via backend');
    }
    
    return response.json();
}

function ContactForm() {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        message: ''
    });

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        
        const message = `New Contact Form Submission:\nName: ${formData.name}\nEmail: ${formData.email}\nMessage: ${formData.message}`;
        
        // Call the backend to send the message to Slack
        await notifySlackBackend(message);
        
        // Optionally reset the form after sending
        setFormData({
            name: '',
            email: '',
            message: ''
        });
    };
    

    return (
        <div className="bambu_tm_contact-container"> {/* New wrapper div */}
            <div>
                <h2>Have questions?</h2>
            </div>
            <div className='bambu_tm_input-fields-container'>
                <form onSubmit={handleSubmit}>
                    <div>
                        <label htmlFor="name">Name:</label>
                        <input type="text" id="name" name="name" value={formData.name} onChange={handleChange} required />
                    </div>
                    <div>
                        <label htmlFor="email">Email:</label>
                        <input type="email" id="email" name="email" value={formData.email} onChange={handleChange} required />
                    </div>
                    <div>
                        <label htmlFor="message">Message:</label>
                        <textarea id="message" name="message" value={formData.message} onChange={handleChange} required></textarea>
                    </div>
                    <button className='bambu_tm_btn' type="submit">Submit</button>
                </form>
            </div>
        </div>
    );
    
}

export default ContactForm;
