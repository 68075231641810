import React from 'react';

function QRCodeDisplay({ qrCodeURL, onClose }) {
    return (
        <div className="modal-container">
            <button className="close-button" onClick={onClose}>X</button>
            <h2>Your Hospitality Card</h2>
            <img 
                src={qrCodeURL} 
                alt="QR Code Wallet Card" 
                onError={(e) => { 
                    e.target.onerror = null; 
                    e.target.src="https://www.freeiconspng.com/thumbs/error-icon/error-icon-32.png"; 
                }} 
                />

            <p>Scan QR code to get your card.</p>
        </div>
    );
}

export default QRCodeDisplay;
