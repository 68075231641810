/**
 * File: ProgramsComponent.js
 * 
 * Synopsis:
 * This React component provides an interface for users to manage programs,
 * interact with attendees, and integrate with HubSpot and MongoDB. It offers
 * functionality for auto-suggestions on attendee names, data submission, and 
 * displays relevant program details fetched from the server.
 * 
 * Author:Esclusa
 * Date: [ October 12, 2023]
 * Version: 1.1
 * 
 * Dependencies:
 * - React
 * - react-autosuggest
 * - Environment variable: API_BASE_URL
 * 
 * Components:
 * - Autosuggest: For auto-suggestion on attendee names
 * 
 * States:
 * - sellers: Array containing list of sellers
 * - formData: Object that holds form data like seller, attendees, etc.
 * - suggestions: Array for suggestions based on user's input
 * - selectedAttendees: Array of selected attendees
 * - isModalVisible: Boolean indicating if success modal is visible
 * 
 * Functions/APIs:
 * - fetchSellers: Fetches list of sellers
 * - onSuggestionsFetchRequested: Fetches contacts based on user's input
 * - handleAttendeeInputChange: Handles attendee input change
 * - handleSuggestionSelected: Saves selected attendee to the database
 * - handleSubmit: Manages form submission and related server operations
 * - removeAttendee: Removes an attendee from the selected list
 * - handleKeyDown: Listens to the keydown event
 * 
 * Usage:
 * To use this component, simply import and render it within a parent component or page.
 * Ensure the API_BASE_URL environment variable is set up correctly.
 */
 import React, { useState, useEffect } from 'react';
 import Autosuggest from 'react-autosuggest';
 
 const apiUrl = process.env.API_BASE_URL || 'http://localhost:3000';
 

 const ProgramsComponent = () => {
 const [brandId, setBrandId] = useState(null);
 const [programId, setProgramId] = useState(null);
 const [sellers, setSellers] = useState([]);
 const [formData, setFormData] = useState({
        seller: '',
        brandId: '',
        programId: '',
        attendees: '',
        timeDate: '',
        company: '',
        email: '',
        industry: '',
        template: '',
        preview: ''
    });
    
const [suggestions, setSuggestions] = useState([]);
const [selectedAttendees, setSelectedAttendees] = useState([]);
const [isModalVisible, setIsModalVisible] = useState(false);
const getSuggestionValue = (suggestion) => {
    return suggestion.name; // Based on the previous use-case, you seem to be displaying the name
};
const renderSuggestion = (suggestion) => {
    return (
        <div>
            {suggestion.name}
        </div>
    );
};
const handleAttendeeInputChange = (event, { newValue }) => {
    setFormData({ ...formData, attendees: newValue });
};
const removeAttendee = (attendeeToRemove) => {
    setSelectedAttendees(prevAttendees => 
        prevAttendees.filter(attendee => attendee.hs_object_id !== attendeeToRemove.hs_object_id)
    );
};
const handleKeyDown = async (event) => {
    if (event.key === "Enter") {
        event.preventDefault(); // Prevent form submission if the input is part of a form

        const attendeeName = formData.attendees.trim(); // Extract name from formData

        // Check if the attendee name is non-empty and not already in the list
        if (attendeeName && !selectedAttendees.some(attendee => attendee.name === attendeeName)) {
            const names = attendeeName.split(' ');
            let firstname, lastname;
    
            if (names.length === 1) {
                firstname = names[0];
                lastname = '';
            } else {
                firstname = names[0];
                lastname = names.slice(1).join(' '); 
            }

            // Fetch suggestions based on the entered attendee name
            try {
                const response = await fetch(`${apiUrl}/api/searchContacts`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({ firstname, lastname })
                });
                const data = await response.json();
                const contactNames = data.results.map(contact => {
                    return {
                        name: `${contact.properties.firstname} ${contact.properties.lastname}`,
                        hs_object_id: contact.properties.hs_object_id,
                        email: contact.properties.email 
                    };
                });
                setSuggestions(contactNames);

                // If there's only one suggestion, assume the user means this particular one
                if (contactNames.length === 1) {
                    setSelectedAttendees(prevAttendees => [...prevAttendees, contactNames[0]]);
                    // Clear the attendees input after addition
                    setFormData({ ...formData, attendees: '' });
                } else if (contactNames.length === 0) {
                    console.error("Error: No matching HubSpot ID for the entered attendee.");
                }
                // For multiple suggestions, no immediate action, we wait for the user to select one
            } catch (error) {
                console.error("Error fetching contacts:", error);
            }
        }
    }
};
const handleSellerChange = (e) => {
    console.log("Dropdown Value:", e.target.value); // Logging dropdown value

    const selectedSeller = sellers.find(seller => seller.seller === e.target.value);

    console.log("Selected Seller:", selectedSeller); // Logging the matched seller from sellers array

    if (selectedSeller) {
        const updatedFormData = {
            ...formData,
            seller: e.target.value,
            brandId: selectedSeller.brandId,
            programId: selectedSeller.programId
        };
        console.log("New formData:", updatedFormData); // Logging the updated formData before setting it

        setFormData(updatedFormData);
    } else {
        setFormData(prevFormData => ({
            ...formData,
            seller: '',
            brandId: '',
            programId: ''
        }));
    }
}

 useEffect(() => {
    async function fetchSellers() {
        try {
            console.log("Fetching sellers from frontend...");
            const response = await fetch(`${apiUrl}/api/sellers`);
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const data = await response.json();
            setSellers(data);
        } catch (error) {
            console.error("There was an issue fetching sellers:", error);
        }
    }
    
    fetchSellers();
}, []);
const onSuggestionsFetchRequested = async ({ value }) => {
    console.log("Value passed to onSuggestionsFetchRequested:", value);
    if (value.length < 3) {
        setSuggestions([]);
        return;
    }
    const names = value.trim().split(' ');
    let firstname, lastname;
    
    if (names.length === 1) {
        firstname = names[0];
        lastname = ''; // Or null, depending on how your backend wants to receive it
    } else {
        firstname = names[0];
        lastname = names.slice(1).join(' '); 
    }
    try {
        const response = await fetch(`${apiUrl}/api/searchContacts`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ firstname, lastname }) // send data as JSON
        });
        

        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const data = await response.json();

        // Process results to extract names
        const contactNames = data.results.map(contact => {
            return {
                name: `${contact.properties.firstname} ${contact.properties.lastname}`,
                hs_object_id: contact.properties.hs_object_id,
                email: contact.properties.email 
            };
        });
        console.log("First contact suggestion:", contactNames[0]);
        setSuggestions(contactNames);
        if (contactNames.length === 1) {
            const attendeeName = contactNames[0].name;
            if (!selectedAttendees.some(attendee => attendee.name === attendeeName)) {
                setSelectedAttendees(prevAttendees => [...prevAttendees, attendeeName]);
            }
            
        }

    } catch (error) {
        console.error("Error fetching contacts:", error);
    }
};
const handleSuggestionSelected = async (event, { suggestion }) => {
    try {
        // Extract the required details from the selected suggestion
        const attendee = {
            name: `${suggestion.name}`.replace(/,/g, '').trim(),
            hs_object_id: suggestion.hs_object_id,
            email: suggestion.email // Note: Ensure the 'properties' key exists in the suggestion object
        };
        console.log('Received attendee:', attendee);

        // Check if this attendee is already selected, if not, add them
        if (!selectedAttendees.some(a => a.hs_object_id === attendee.hs_object_id)) {
            setSelectedAttendees(prevAttendees => [...prevAttendees, attendee]);
        }

        // Clear the attendees input after selecting
        setFormData({ ...formData, attendees: '' });

        // Make an API call to save the attendee to MongoDB
        const response = await fetch(`${apiUrl}/api/saveAttendee`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(attendee)
        });

        if (response.ok) {
            console.log('Attendee saved in MongoDB successfully.');
            // Store attendee details in the session storage for later use
            window.sessionStorage.setItem('selectedAttendee', JSON.stringify(attendee));
        } else {
            console.error('Error saving attendee in MongoDB.');
            const errorData = await response.json();
            throw new Error(errorData.message || 'Error saving attendee');
        }
    } catch (error) {
        console.error("Error in handleSuggestionSelected:", error);
    }
};
const handleSubmit = async (event) => {
    event.preventDefault();

    try {
        console.log('Sending Form Data:', JSON.stringify({
            ...formData,
            attendees: selectedAttendees
        }));  // Log the form data being sent to the backend

        const response = await fetch(`${apiUrl}/api/saveFormData`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                ...formData,
                attendees: selectedAttendees
            })
        });

        const result = await response.json();

        console.log('Response received from server:', result);  // Log the entire server response

        if (response.status === 200 && result.success === true) {
            console.log('Form data processed successfully. Server response:', result);
            setIsModalVisible(true);
        } else {
            console.warn('Unexpected server response:', response.status, result);
            throw new Error(result.message || 'Error processing the request.');
        }        
    } catch (error) {
        console.error("There was an issue processing the form:", error.message);
    }
};

return (
    <div className="main-container">
        {isModalVisible && (
            <div className="bambu_tm_modal-container">
                <div className="bambu_tm_modal">
                    <h2>Your request has been submitted!</h2>
                    <button onClick={() => setIsModalVisible(false)}>Close</button>
                </div>
            </div>
        )}

        <div className="bambu_tm_form-container">
            <form className="bambu_tm_input-fields-container" onSubmit={handleSubmit}>
                <div className="bambu_tm-select-wrapper">
                <select value={formData.seller} onChange={handleSellerChange}>
                    <option value="" disabled>Select a seller</option>
                    {sellers.map(sellerObj => (
                        <option key={sellerObj._id} value={sellerObj.seller}>
                            {sellerObj.seller}
                        </option>
                    ))}
                </select>


                </div>
                <div className="bambu_tm-selected-attendees-list">
                <Autosuggest
                    suggestions={suggestions}
                    onSuggestionsFetchRequested={() => {}}
                    onSuggestionsClearRequested={() => setSuggestions([])}
                    getSuggestionValue={getSuggestionValue}
                    renderSuggestion={renderSuggestion}
                    onSuggestionSelected={handleSuggestionSelected}
                    inputProps={{
                        placeholder: "Type attendee's name and press Enter to search...",
                        value: formData.attendees,
                        onChange: handleAttendeeInputChange,
                        onKeyDown: handleKeyDown
                    }}
                />
                
                <div className="bambu_tm-selected-attendees-list">
                    {selectedAttendees.map((attendee, index) => (
                        <div key={index} className="bambu_tm-selected-attendees-list">
                            {attendee.name}
                            <button onClick={() => removeAttendee(attendee)}>X</button> 
                        </div>
                    ))}
                </div>
             </div>
                <input 
                    type="datetime-local" 
                    value={formData.timeDate} 
                    onChange={e => setFormData({ ...formData, timeDate: e.target.value })} 
                />

                <div className="bambu_tm-select-wrapper">    
                    <select 
                        value={formData.industry} 
                        onChange={e => setFormData({ ...formData, industry: e.target.value })}
                    >
                        <option value="" disabled>Select your Industry</option>
                        <option value="Retail">Retail</option>
                        <option value="Hospitality">Hospitality</option>
                        <option value="Other">Other</option>
                    </select>
                </div>

                <div className="bambu_tm-select-wrapper">    
                    <select 
                        value={formData.template} 
                        onChange={e => setFormData({ ...formData, template: e.target.value })}
                    >
                        <option value="red">Red</option>
                        <option value="blue">Blue</option>
                        <option value="green">Green</option>
                    </select>
                </div>

                <button type="submit" className='bambu_tm_btn'>Add</button>
            </form>

            {/* Placeholder for a future list of programs */}
            <ul>
                {/* List of Programs could go here */}
            </ul>
        </div>
    </div>
);
};
export default ProgramsComponent;
