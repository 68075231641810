import React, { useState, useEffect, useRef } from 'react';
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from "react-places-autocomplete";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'; // Assuming you have FontAwesome installed.
import { library } from '@fortawesome/fontawesome-svg-core';
import { faEnvelope, faListAlt, faSearch, faTimes, faCheckCircle, faSpinner } from '@fortawesome/free-solid-svg-icons';

library.add(faEnvelope, faListAlt, faSearch, faTimes, faCheckCircle, faSpinner);
const userEmail = document.cookie.replace(/(?:(?:^|.*;\s*)userEmail\s*\=\s*([^;]*).*$)|^.*$/, "$1");
const LocationAlerts = ({onClose }) => {
    const [currentLocation, setCurrentLocation] = useState(null);
    const [address, setAddress] = useState("");
    const [message, setMessage] = useState("");
    const [selectedLocation, setSelectedLocation] = useState({ lat: null, lng: null });
    const [restaurants, setRestaurants] = useState([]);
    const mapRef = useRef(null); // Initialize useRef
    const userEmail = document.cookie.includes('userEmail') ? document.cookie.replace(/(?:(?:^|.*;\s*)userEmail\s*\=\s*([^;]*).*$)|^.*$/, "$1") : '';
    const [isLoading, setIsLoading] = useState(true);
    const [showSuccessModal, setShowSuccessModal] = useState(false);
    const [geoPermission, setGeoPermission] = useState('prompt'); // Initialized as 'prompt'
    const [showErrorModal, setShowErrorModal] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    const handleSelect = async (value) => {
        setAddress(value);  // Set the address to the state
    
        try {
            // Get geocode information for the selected address
            const results = await geocodeByAddress(value);
            const latLng = await getLatLng(results[0]);
    
            setSelectedLocation(latLng);  // Update the state with latitude and longitude
    
            // For Debugging: You can print the selected address and its lat/lng
            console.log("Selected address:", value);
            console.log("Latitude:", latLng.lat);
            console.log("Longitude:", latLng.lng);
        } catch (error) {
            console.error("Error getting geolocation:", error);
        }
    };
    
    
    
    useEffect(() => {
        // Check the geolocation permission first
        if (navigator.permissions) {
            navigator.permissions.query({ name: 'geolocation' }).then(function (result) {
                setGeoPermission(result.state);
                result.onchange = () => {
                    setGeoPermission(result.state);
                };
            });
        }

        // If the permission is granted or not yet decided, try to get geolocation
        if (geoPermission === 'granted' || geoPermission === 'prompt') {
            navigator.geolocation.getCurrentPosition(
                position => {
                    const loc = {
                        lat: position.coords.latitude,
                        lng: position.coords.longitude
                    };
                    console.log("User's geolocation:", loc);
                    setCurrentLocation(loc);
                },
                error => {
                    switch (error.code) {
                        case error.PERMISSION_DENIED:
                            setErrorMessage("User denied the request for Geolocation. Please allow access for a better experience.");
                            break;
                        case error.POSITION_UNAVAILABLE:
                            setErrorMessage("Location information is unavailable.");
                            break;
                        case error.TIMEOUT:
                            setErrorMessage("The request to get user location timed out.");
                            break;
                        case error.UNKNOWN_ERROR:
                            setErrorMessage("An unknown error occurred.");
                            break;
                    }
                    setShowErrorModal(true);                    
                }
            );
        }
    }, [geoPermission]);

    useEffect(() => {
        if (currentLocation && window.google && window.google.maps && window.google.maps.places && mapRef.current) {
            const service = new window.google.maps.places.PlacesService(mapRef.current.state.map);

            service.nearbySearch({
                location: currentLocation,
                radius: 5000,  // Adjust as needed
                type: ['restaurant']
            }, (results, status) => {
                if (status === window.google.maps.places.PlacesServiceStatus.OK) {
                    setRestaurants(results);
                }
            });
        }
    }, [currentLocation]);

    const mapStyles = {
        height: "400px",
        width: "100%"
    };
    const handleSubmit = async () => {
        if (!selectedLocation) {
            console.error('Location not selected!');
            return;
        }
    
        setIsLoading(true);  // Assuming you have an isLoading state to control a loading spinner or similar
    
        const requestBody = {
            brandId: 9,            // Static values as per your example
            programId: 265,
            templateId: 180,
            email: userEmail,
            currentMessage: message,
            latitude: selectedLocation.lat,
            longitude: selectedLocation.lng
            
        };
    
        try {
            const response = await fetch('/updateCard', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(requestBody),
            });
    
            const responseData = await response.json();
    
            if (!response.ok) {
                throw new Error(responseData.error);
            }
    
            setShowSuccessModal(true);  // Assuming you have a modal or a method to show a success message
    
        } catch (error) {
            setIsLoading(false);
            console.error('Error sending notification:', error.message);
            
        } finally {
            setIsLoading(false);
        }
    };
    
    
    return (
        
        <div className='bambu_tm_input-fields-container'>
            <button className="close-button" onClick={onClose}>X</button>
            
            {isLoading && <div className="bambu_tm_loading-modal-overlay">Loading...</div>}
            {showSuccessModal && (
                    <div className="bambu_tm_success-modal-overlay">
                        <div className="bambu_tm_success-modal-content">
                        <button className="bambu_tm_close-button" onClick={() => setShowSuccessModal(false)}>X</button>
                           <div>&nbsp;</div> Message loaded for the location!
                           <FontAwesomeIcon icon="check-circle" style={{ color: 'green' }} />
                        </div>
                    </div>
                )}
            {showErrorModal && (
            <div className="bambu_tm_error-modal-overlay">
                <div className="bambu_tm_error-modal-content">
                    <button className="bambu_tm_close-button" onClick={() => setShowErrorModal(false)}>X</button>
                    <div>&nbsp;</div> 
                    {errorMessage}
                </div>
            </div>
        )}

            {currentLocation && 
                <GoogleMap
                    ref={mapRef}
                    mapContainerStyle={mapStyles}
                    zoom={10}
                    center={currentLocation}
                    onLoad={() => setIsLoading(false)}  // Set isLoading to false when the map has loaded
                >
                    <Marker position={currentLocation} />
                    {restaurants.map(restaurant => (
                        <Marker 
                            key={restaurant.place_id} 
                            position={{
                                lat: restaurant.geometry.location.lat(), 
                                lng: restaurant.geometry.location.lng()
                            }}
                            title={restaurant.name}
                        />
                    ))}
                </GoogleMap>
            }
            
            <div className="location-alerts-form">
                <div className="form-field">
                    <label>Email:</label>
                    <input type="text" value={userEmail} readOnly />
                </div> 
        
                <div className="form-field">
                    <PlacesAutocomplete value={address} onChange={setAddress} onSelect={handleSelect}>
                        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                            <>
                                <label>Set Location:</label>
                                <input {...getInputProps({ placeholder: 'Set Location' })} />
                                {loading && <div>Loading...</div>}
                                <div>
                                    {suggestions.map(suggestion => (
                                        <div {...getSuggestionItemProps(suggestion)}>
                                            {suggestion.description}
                                        </div>
                                    ))}
                                </div>
                            </>
                        )}
                    </PlacesAutocomplete>
                </div>
                
                <div className="form-field">
                    <label>Message:</label>
                    <textarea 
                        value={message} 
                        onChange={(e) => setMessage(e.target.value)} 
                        placeholder="Message" 
                    />
                </div>
                
                <button className='bambu_tm_btn' onClick={handleSubmit}>Submit</button>
            </div>
        </div>
    ); 
        
}

export default LocationAlerts;
