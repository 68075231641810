import React from 'react';

function EditorButtons() {
    return (
        <div>
            <button className="editorButton">Save</button>
            <button className="editorButton">Publish</button>
        </div>
    );
}

export default EditorButtons;
