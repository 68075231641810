import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Navbar from './components/Navbar';
import HeroSection from './components/HeroSection';
import Footer from './components/Footer';
import Preloader from './components/Preloader';
import Demos from './components/Demos';
import RockTheVote from './components/RockTheVote/RockTheVote';
import InsuranceBundle from './components/insurance/InsuranceBundle';
import IdCard from './components/idcard/IdCard';
import RestaurantPage from './components/Restaurant/RestaurantPage';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import RetailDemoSystem from './components/Retail/retailDemoSystem';
import RequestForm from './components/admin/RequestForm';
import ApplePayWithLoyalty from './components/Restaurant/ApplePayDemo';
import { HeroModalProvider } from './context/heroModal';  
import ContactForm from './components/contact';
import './css/main.css';

function App() {
    return (
        <DndProvider backend={HTML5Backend}>
            <Router>
                <HeroModalProvider>  
                    <div className="site-container">
                        <div className="App">
                            <Preloader />
                            <Navbar />
                            <div className="bambu_tm_all_wrap"></div>
                            <Routes>
                                <Route path="/" element={<HeroSection />} />
                                <Route path="/demos" element={<Demos />} />
                                <Route path="/demos/rock-the-vote" element={<RockTheVote />} />
                                <Route path="/demos/insurance-bundle" element={<InsuranceBundle />} />
                                <Route path="/demos/idcards" element={<IdCard />} />
                                <Route path="/demos/restaurant" element={<RestaurantPage />} />
                                <Route path="/demos/retail-demo-system" element={<RetailDemoSystem />} />
                                <Route path="/demos/apple-pay-loyalty" element={<ApplePayWithLoyalty />} />
                                <Route path="/demos/admin/form" element={<RequestForm />} />
                                <Route path="/contact" element={<ContactForm />} />
                            </Routes>
                        </div>
                    </div>
                </HeroModalProvider>
            </Router>
        </DndProvider>
        
    );
}

export default App;
