import React, { useState } from 'react';
import './RockTheVote.css';
import Cookies from 'js-cookie'; 
//import { getPandaToken, bambuCreateCard } from '../../../bambu-sdk/src/services/bambuServices';
import { PARTY_INFO } from '../../config/partyConfig';
import QRCode from 'qrcode.react';

function Modal({ isOpen, onClose, onSubmit, party }) {
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [candidate, setCandidate] = useState('');
    const [independentCandidate, setIndependentCandidate] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [qrCodeURL, setQrCodeURL] = useState(null);

    const brandId = parseInt(process.env.BRAND_ID, 10);
    const programId = parseInt(process.env.PROGRAM_ID, 10);
    
    if (!isOpen) return null;

    const handleFormSubmit = async () => {
        setIsLoading(true);
        
        let templateTierId;
    
        if (party === 'republican') {
            switch(candidate.toLowerCase()) {
                case 'donald trump':
                    templateTierId = 145;
                    break;
                case 'ron desantis':
                    templateTierId = 146;
                    break;
                case 'chris christie':
                    templateTierId = 148;
                    break;
                default:
                    templateTierId = null;
                    break;
            }
        } else if (party === 'democrat' && candidate.toLowerCase() === 'joe biden') {
            templateTierId = 149;
        } else if (party === 'independent') {
            templateTierId = null;
        }
    
        try {
            const tokenResponse = await fetch('/getPandaToken');
            const tokenData = await tokenResponse.json();
            Cookies.set('accessToken', tokenData.token);
            
            const cardData = {
                accessToken: tokenData.token,
                firstName,
                lastName,
                email,
                backendEndpoint: process.env.API_BASE_URL,
                brandId,
                programId,
                templateTierId
            };
            
            const createCardResponse = await fetch('/createCard', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(cardData)
            });
    
            const responseData = await createCardResponse.json();
            console.log("Received responseData:", responseData);
            if (responseData && responseData.downloadUrl) {
                const completeDownloadURL = `https://wallet-pass-sandbox.bambumeta.software${responseData.downloadUrl}`;
                setQrCodeURL(completeDownloadURL);
            } else {
                console.error("Download URL not found in response data");
            }
            //onSubmit({ firstName, lastName, email, candidate });
    
        } catch (error) {
            console.error('Error occurred:', error);
        } finally {
            setIsLoading(false);
        }
    };
    

    return (
        <div className="modal-overlay">
            <div className="modal">
                <img src={PARTY_INFO[party].logo} alt={`${party} Logo`} className="modal-logo" />
                <button className="close-button" onClick={onClose}>X</button>
                <div className="input-container">
                    <input value={firstName} onChange={e => setFirstName(e.target.value)} placeholder="First Name" />
                    <input value={lastName} onChange={e => setLastName(e.target.value)} placeholder="Last Name" />
                    <input type="email" value={email} onChange={e => setEmail(e.target.value)} placeholder="Email" />
                    
                    {party === 'independent' ? (
                        <input value={independentCandidate} onChange={e => setIndependentCandidate(e.target.value)} placeholder="Your Candidate" />
                    ) : (
                        <select value={candidate} onChange={e => setCandidate(e.target.value)}>
                            <option value="">Select a candidate</option>
                            {PARTY_INFO[party].candidates.map((cand, index) => (
                                <option key={index} value={cand}>{cand}</option>
                            ))}
                        </select>
                    )}
                </div>
                {qrCodeURL ? (
                    <div>
                        <QRCode value={qrCodeURL} />
                        <p>Scan the QR Code to download</p>
                    </div>
                ) : isLoading ? (
                    <div className="spinner">Loading...</div>
                ) : (
                    <button className="btn" onClick={handleFormSubmit}>Submit</button>
                )}
            </div>
        </div>
    );
}

export default Modal;
