import React, { useState, useRef, useEffect } from 'react';
import { motion } from 'framer-motion';
import TabData from '../data/tabs.json';
import PushNotificationModal from '../components/Restaurant/PushNotifications';
import ReservationForm from '../components/Restaurant/reservationForm';

const UseCaseTemplate = ({ industry, onClose }) => {
    const getIndustryUseCases = (industryInput) => {
        const useCaseData = TabData.find(
            item => item.industry.toLowerCase() === industryInput.toLowerCase() && item.type === 'usecase'
        );
        console.log(`Industry: ${industryInput}, Use Case Data:`, useCaseData); // Debug
        return useCaseData ? useCaseData.points : [];
    };
    
    const industryUseCases = getIndustryUseCases(industry);
    console.log("Industry Use Cases:", industryUseCases); // Debug
    const [modalHistory, setModalHistory] = useState(['USE_CASE']);
    const handleModalChange = (newContent) => {
        setModalHistory(prevHistory => [...prevHistory, newContent]);
    };
    const handleBack = () => {
        setModalHistory(prevHistory => {
            if (prevHistory.length <= 1) return prevHistory; // Ensure you don't remove the last state
            return prevHistory.slice(0, -1); // Remove the last state
        });
    };
        
    function importAll(r) {
        return r.keys().map(r);
    }

    const images = importAll(require.context('../shared/img', false, /\.(png|jpe?g|svg)$/));
    console.log("Imported images:", images); // Debug

    const tileVariants = {
        hidden: { opacity: 0, y: 30 },
        visible: { opacity: 1, y: 0 }
    };

    const containerVariants = {
        hidden: { opacity: 0 },
        visible: { opacity: 1 }
    };

    const cardTransition = {
        duration: 0.5
    };

    const modalOverlayRef = useRef(null);

    const handleClickOutside = (event) => {
        if (event.target === modalOverlayRef.current) {
            console.log("Clicked outside the modal"); // Debug
        }
    };

    useEffect(() => {
        console.log("Adding mousedown event listener"); // Debug
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            console.log("Removing mousedown event listener"); // Debug
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    const [currentModalContent, setCurrentModalContent] = useState('USE_CASE');
    console.log(`Current Modal Content: ${currentModalContent}`); // Debug
    
    const handleCloseClick = () => {
        console.log("Close button clicked"); // Debug
        setCurrentModalContent('USE_CASE');
    }

    const CloseButton = ({ handleClose }) => (
        <button className="bambu_tm_btn" onClick={(e) => {
            handleClose();
            e.stopPropagation();
        }}>Close</button>
    );

    const renderModalContent = () => {
        switch (currentModalContent) {
            case 'USE_CASE':
                return (
                    <div className="bambu_tm-usecase-modal">
                        <div className="gridContainer">
                            <div className="bambu_tm-usecase-modal-title">Choose a Use Case</div>
                            {industryUseCases.map((useCase, index) => (
                                <motion.div
                                    key={index}
                                    className="useCaseTile"
                                    variants={tileVariants}
                                    initial="hidden"
                                    animate="visible"
                                    transition={cardTransition}
                                    onClick={() => {
                                        console.log(`Clicked on useCase with subtitle: ${useCase.subtitle}`); // Debug
                                        if (useCase.subtitle === "Segmentation") {
                                            setCurrentModalContent('PUSH_NOTIFICATION');
                                        } else if (useCase.subtitle === "Reservations") {
                                            setCurrentModalContent('RESERVATION');
                                        }
                                    }}
                                >
                                    <img src={images.find(img => img.includes(useCase.image.split('.')[0]))} alt={useCase.subtitle} />
                                    <div className="subtitle">{useCase.subtitle}</div>
                                    <div className="description">{useCase.description}</div>
                                </motion.div>
                            ))}
                        </div>
                        
                    </div>
                );
            case 'PUSH_NOTIFICATION':
                return (
                    <PushNotificationModal onClose={() => setCurrentModalContent('USE_CASE')} />
                );
            case 'RESERVATION':
                return (
                    <ReservationForm onClose={() => setCurrentModalContent('USE_CASE')} />
                );
            default:
                console.log(`Unknown currentModalContent value: ${currentModalContent}`); // Debug
                return null;
        }
    };
   
    return (
        <div 
            ref={modalOverlayRef} 
            className="modalOverlay" 
            onClick={handleClickOutside}
        >   
        <div className='bambu_tm-usecase-modal'>
            <motion.div 
                className='bambu_tm_topbar-modal-content'
                initial="hidden"
                animate="visible"
                variants={containerVariants}
                transition={cardTransition}
                onClick={(e) => e.stopPropagation()}
            >
                
                {renderModalContent()}
            </motion.div>
            <div className='bambu_tm-topbar-button-container'>
                <button onClick={CloseButton} className="bambu_tm_btn">Back</button>
                </div>
            </div>
        </div>
    ); 
      
    
};

export default UseCaseTemplate;
