import React, { createContext, useState } from 'react';

export const HeroModalContext = createContext();

export const HeroModalProvider = ({ children }) => {
    const [isHeroModalOpen, setIsHeroModalOpen] = useState(false);
    const [heroModalComponent, setHeroModalComponent] = useState(null);

    return (
        <HeroModalContext.Provider value={{
            isHeroModalOpen,
            setIsHeroModalOpen,
            heroModalComponent,
            setHeroModalComponent
        }}>
            {children}
        </HeroModalContext.Provider>
    );
};
