import React, { useContext, useState } from 'react';
import { HeroModalContext } from '../../context/heroModal'; // Ensure this path is correct based on your directory structure
import Cookies from 'js-cookie';
import AppleWalletSVG from '../../shared/img/US-UK_Add_to_Apple_Wallet_RGB_101421.svg';
import GooglePaySVG from '../../shared/img/Save to Google Pay.svg';
import LoadingModal from './LoadingModal';
import QRCodeDisplay from './QRCodeDisplay'; 
import UserDetailsForm from './UserDetailsForm';

const WALLET_API_URL = 'https://wallet-pass-sandbox.bambumeta.software';

const ActivationFormModal = ({ closeFunc}) => {

    // Use the context
    const { isHeroModalOpen } = useContext(HeroModalContext);

    // State management
    const [formData, setFormData] = useState({
        firstName: "",
        lastName: "",
        email: "",
        company: ""
    });
    const [qrCodeURL, setQRCodeURL] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [downloadUrl, setDownloadUrl] = useState('');

    // Handlers
    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    };

    const handleFormSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        try {
            const responseData = await generateCard(formData);
            if (responseData && responseData.downloadUrl) {
                setDownloadUrl(responseData.downloadUrl);
                setQRCodeURL(responseData.qrCodeURL);
                Cookies.set('userEmail', formData.email, { expires: 7 });
            } else {
                throw new Error("Invalid response from the server.");
            }
        } catch (error) {
            console.error(error.message);
        } finally {
            setIsLoading(false);
        }
    };

    const handleWalletButtonClick = () => {
        if (downloadUrl) {
            window.location.href = downloadUrl;
        } else {
            console.error("Download URL not available.");
        }
    };

    // Render
    if (!isHeroModalOpen) return null;

    if (isLoading) {
        return <LoadingModal />;
    }

    if (qrCodeURL) {
        return <QRCodeDisplay qrCodeURL={qrCodeURL} downloadUrl={downloadUrl} closeFunc={closeFunc} />;
    }

    return <UserDetailsForm formData={formData} handleChange={handleChange} handleFormSubmit={handleFormSubmit} closeFunc={closeFunc} />;
}

// The generateCard function remains within the main component file
const generateCard = async (formData) => {
    const apiBaseUrl = process.env.REACT_APP_API_BASE_URL || 'http://localhost:3000';
    const response = await fetch(`${apiBaseUrl}/api/generateCard`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(formData)
    });

    const responseData = await response.json();
    if (responseData && responseData.downloadUrl) {
        responseData.downloadUrl = `${WALLET_API_URL}${responseData.downloadUrl}`;
    }
    return responseData;
}

export default ActivationFormModal;
