import { useState, useEffect } from 'react';
import { useSwipeable } from 'react-swipeable';
import hospitalityBaseImage from '../../shared/img/hospitality_base.png';
import hospitalityDinnerImage from '../../shared/img/restaurant.png';
import hospitalityRewardImage from '../../shared/img/hospitality_loyalty.png';
import hospitalityMessageImage from '../../shared/img/hospitality_message.png';
import hospitalityLocationImage from '../../shared/img/hospitality_location.png';
import Cookies from 'js-cookie';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight, faChevronUp, faChevronDown } from '@fortawesome/free-solid-svg-icons';

const defaultUseCases = [
    { title: "Activation", description: "7 Million in 7 Minutes!", image: hospitalityBaseImage },
    { title: "Reservations", description: "Description for Use Case 2", image: hospitalityDinnerImage },
    { title: "Engagement", description: "Description for Use Case 3", image: hospitalityRewardImage },
    { title: "Messaging", description: "Description for Use Case 4", image: hospitalityMessageImage },
    { title: "Geo-Location", description: "Description for Use Case 5", image: hospitalityLocationImage },
];

const MAX_TILES = 3; 
const useIsMobile = (breakpoint = 768) => {
    const [isMobile, setIsMobile] = useState(window.innerWidth <= breakpoint);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= breakpoint);
        };

        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [breakpoint]);

    return isMobile;
};
const UseCasesCarousel = ({ onUseCaseClick, title }) => {
    const [currentIndex, setCurrentIndex] = useState(0);
    const userEmail = Cookies.get('userEmail');
    const isMobile = useIsMobile();

    const handleUseCaseClick = (useCase) => {
        onUseCaseClick && onUseCaseClick(useCase);
    };

    const navigateLeft = () => {
        if (currentIndex > 0) {
            setCurrentIndex(currentIndex - 1);
        }
    };    

    const navigateRight = () => {
        if (currentIndex < defaultUseCases.length - MAX_TILES) {
            setCurrentIndex(currentIndex + 1);
        }
    }
    
    // Using useSwipeable to get the swipe handlers
    const handlers = useSwipeable({
        onSwipedLeft: navigateRight,
        onSwipedRight: navigateLeft,
    });

    return (
        
        <div className="carouselContainerWrapper">
            <h2 className="carouselTitle">Hospitality Use cases</h2> {/* <-- Display the title here */}
        <button onClick={navigateLeft} className="chevronButton">
            <FontAwesomeIcon icon={isMobile ? faChevronUp : faChevronLeft} />
        </button>
            
            <div {...handlers} className="carouselContainer">
                {defaultUseCases.slice(currentIndex, currentIndex + MAX_TILES).map((useCase, index) => (
                    <div
                        key={index}
                        className={`useCaseContainer ${index === currentIndex ? 'active' : ''}`}
                        onClick={() => handleUseCaseClick(useCase)}
                        tabIndex="0"
                        role="button"
                        aria-label={`Select ${useCase.title}`}
                    >
                        <img src={useCase.image} alt={useCase.title} className="useCaseImage" />
                        <h3 className="useCaseTitle">{useCase.title}</h3>
                    </div>
                ))}
            </div> {/* Closing div for .carouselContainer here */}
    
            <button onClick={navigateRight} disabled={currentIndex === defaultUseCases.length - MAX_TILES} className="chevronButton">
                <FontAwesomeIcon icon={isMobile ? faChevronDown : faChevronRight} />
            </button>
        </div>
    );
}    

export default UseCasesCarousel;
