import React, { useState, useEffect } from 'react';
import Cookies from 'js-cookie';
import QRCodeDisplay from './QRCodeDisplay';  // Adjust the path as needed
import AppleWalletSVG from '../../shared/img/US-UK_Add_to_Apple_Wallet_RGB_101421.svg';
import GooglePaySVG from '../../shared/img/Save to Google Pay.svg';
//const WALLET_BASE_URL = 'https://wallet-pass-sandbox.bambumeta.software';



function ActivationForm({ onSubmit, onClose }) {
    const [formData, setFormData] = useState({
        firstName: "",
        lastName: "",
        email: "",
        company: ""
    });
    const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
    const isAndroid = /Android/.test(navigator.userAgent);
    const [qrCodeURL, setQRCodeURL] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState('');
    const [isMobile, setIsMobile] = useState(false);
    const [showQRCodeLink, setShowQRCodeLink] = useState(false);
    const getTemplateTierIds = () => 130;
    const [downloadUrl, setDownloadUrl] = useState('');
    const [passId, setPassId] = useState(null);

    //const handleShareClick = () => {
        // Logic to share or navigate to the desired URL
    //    window.location.href = "YOUR_DESIRED_LINK_HERE"; // replace with your desired link
    //}
    //const walletSVG = isIOS ? AppleWalletSVG : isAndroid ? GooglePaySVG : null;
    
    useEffect(() => {
        // Check if the device is mobile
        const checkMobile = () => {
            const mobileView = window.innerWidth <= 768;
            setIsMobile(mobileView);
        };

        checkMobile(); // initial check
        window.addEventListener('resize', checkMobile); // add listener to detect window resize

        return () => {
            window.removeEventListener('resize', checkMobile); // cleanup listener on component unmount
        }
    }, []);
    
    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    };

    const { firstName, lastName, email, company } = formData;

    const handleFormSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        const templateStage = getTemplateTierIds();
    
        try {
            const apiBaseUrl = process.env.REACT_APP_API_BASE_URL || 'http://localhost:3000';
            let token = Cookies.get('accessToken');
            const cardData = {
                accessToken: token,
                brandId: 9,
                programId: 265,
                templateId: 180,
                templateTierId: templateStage,
                person: {
                    firstName,
                    lastName,
                    email,
                    phone: '',
                    company
                },
                passdata: {
                    metaData: {
                        seller: 'Michael'
                    },
                },
            };
    
            const createCardResponse = await fetch(`${apiBaseUrl}/createCard`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(cardData)
            });
    
            const responseData = await createCardResponse.json();
            console.log("API responseData:", responseData);
            const downloadUrl = responseData.createdCards[0]?.downloadUrl;
            const qrCodeURL = responseData.qrCodeURL;
            const obtainedPassId = responseData.createdCards[0]?.passId;
            //const qrCodeURL = responseData?.issueWalletResponse?.qrCodeURL;

    
            if (downloadUrl && qrCodeURL) {
                Cookies.set('userEmail', email, { expires: 365 });
                setQRCodeURL(qrCodeURL);
                setDownloadUrl(downloadUrl);
                setPassId(obtainedPassId);
                onSubmit && onSubmit(qrCodeURL, downloadUrl);
            } else {
                throw new Error("Required data not found in response.");
            }
            
    
        } catch (error) {
            console.error('Error occurred:', error);
            setError(error.message || 'Failed to create card. Please check your input and try again.');
        } finally {
            setIsLoading(false);
        }
    };
    const handleWalletButtonClick = async () => {
        if(!passId) {
            console.error('PassId is not available.');
            return;
        }
    
        try {
            const response = await fetch(`https://sandbox.api.bambumeta.software/binary/9/passes/${passId}`, {
                method: 'GET',
                headers: {
                    // Any necessary headers
                },
            });
    
            if (response.ok) {
                const blob = await response.blob();
                const objectUrl = URL.createObjectURL(blob);
    
                const a = document.createElement('a');
                a.href = objectUrl;
                a.download = 'pass.pkpass'; // You can specify a filename if desired
                document.body.appendChild(a);
                a.click();
                a.remove();
            } else {
                console.error('Error fetching data:', response.statusText);
            }
    
        } catch (error) {
            console.error('Error occurred:', error);
            setError(error.message || 'Failed to fetch pass data.');
        }
    };
    
    
    return (
        <div>
            {isLoading ? (
                <div className='bambu_tm_loading-modal-content'>
                <video width="320" height="240" autoPlay loop muted>
                    <source src="../../shared/img/loading.mp4" type="video/mp4" />
                    Your browser does not support the video tag.
                </video>
            </div>
            ) : error ? (
                <div>
                    <h2>Error</h2>
                    <p>{error}</p>
                </div>
            ) : qrCodeURL ? (
                isMobile ? (
                    <div>
                        {isIOS ? (
                            <a href="#" onClick={handleWalletButtonClick}>
                                <button className="bambu_tm_close-button" onClick={onClose}>X</button>
                                <h4>Add you wallet card</h4>
                                 <img src={AppleWalletSVG} alt="Apple Wallet Icon" />
                            </a>
                        ) : isAndroid ? (
                            <a href="#" onClick={handleWalletButtonClick}>
                                <button className="bambu_tm_close-button" onClick={onClose}>X</button>
                                <h4>Your Hospitality Card</h4>
                                <img src={GooglePaySVG} alt="Google Pay Icon" />
                            </a>
                        ) : (
                            // This condition will not be met because if it's mobile, it's either iOS or Android.
                            // But it's here for the sake of completeness.
                            <div>Unsupported mobile device</div>
                        )}
                    </div>
                ) : (
                    <QRCodeDisplay qrCodeURL={qrCodeURL} onClose={onClose} />
                )
            ) : (
                <div>
                    <button className="bambu_tm_close-button" onClick={onClose}>X</button>
                    <form onSubmit={handleFormSubmit}>
                        <div className="bambu_tm_input-fields-container">
                        <h2>Tell us about yourself</h2>
                            <input id="first_name" name="firstName" type="text" placeholder="First Name" onChange={handleChange} />
                            <input className="form-input" id="last_name" name="lastName" type="text" placeholder="Last Name" onChange={handleChange} />
                            <input className="form-input" id="email" name="email" type="text" placeholder="Email" onChange={handleChange} />
                            <input className="form-input" id="company" name="company" type="text" placeholder="Company" onChange={handleChange} />
                            <button type="submit" className=' bambu_tm_btn'>Submit</button>
                        {/* ... [rest of the form elements] */}
                        </div>
                    </form>
                </div>
            )}
        </div>
    );
}

export default ActivationForm;
