import { useState, useEffect } from 'react';

const useContactDetails = (email, company) => {
    const [contactDetails, setContactDetails] = useState({});

    useEffect(() => {
        if (email && company) {
            fetch(`${process.env.BACKEND_URL}/api/getContactDetails?company=${company}&email=${email}`)
                .then(response => response.json())
                .then(data => setContactDetails(data))
                .catch(error => console.error("Failed to fetch contact details", error));
        }
    }, [email, company]);

    return contactDetails;
};

export default useContactDetails;
