// DraggableIcon.js
import React from 'react';
import { useDrag } from 'react-dnd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function DraggableIcon({ icon, label }) {
    const [{ isDragging }, drag] = useDrag({
        type: 'ICON',
        item: { iconType: label },
        collect: (monitor) => ({
            isDragging: !!monitor.isDragging()
        })
    });

    return (
        <div ref={drag} style={{ opacity: isDragging ? 0.5 : 1 }}>
            <FontAwesomeIcon icon={icon} /> {label}
        </div>
    );
}

export default DraggableIcon;
