const ContactDetails = ({ company, contactDetails }) => {
    return (
        <>
            <div className="bambu_tm_name_wrap">
                <h3><span>{company ? company : "BambuMeta"}</span></h3>
            </div>
            <div className="bambu_tm_job_wrap">
                <p>
                    {contactDetails.properties?.firstname && contactDetails.properties?.lastname ? 
                        `${contactDetails.properties.firstname} ${contactDetails.properties.lastname}, Thank you for joining our meeting` : 
                        "Redefining Technology"}
                </p>
            </div>
            <div className="spacing-top">
                <div className="bambu_tm_job_wrap">
                    <p>
                        Placeholder content goes here. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus lacinia odio vitae vestibulum.
                    </p>
                </div>
            </div>
        </>
    );
};

export default ContactDetails;
