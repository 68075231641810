import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarAlt, faClock, faUsers, faChevronDown, faCheckCircle } from '@fortawesome/free-solid-svg-icons';



function ReservationForm({ onSubmit, onClose }) {
    const userEmail = document.cookie.includes('userEmail') ? document.cookie.replace(/(?:(?:^|.*;\s*)userEmail\s*\=\s*([^;]*).*$)|^.*$/, "$1") : '';
    
    const [reservationData, setReservationData] = useState({
        date: "",
        time: "",
        partySize: "",
        email: userEmail
    });

    const [isLoading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState(null);

    const handleChange = e => {
        const { name, value } = e.target;
        setReservationData(prevData => ({ ...prevData, [name]: value }));
    };
    
    const handleSubmit = async e => {
        e.preventDefault();
        setLoading(true);
        setError(null);
        console.log('User Email:', userEmail);
    
        let serverDate = reservationData.date;
        if (reservationData.date) {
            const [month, day, year] = reservationData.date.split('/');
            serverDate = `20${year}-${month}-${day}`;
        }
    
        try {
            const requestBody = {
                brandId: 9,
                programId: 265,
                templateId: 180,
                templateTierId: 115,
               email: userEmail, // Using the userEmail; adjust as necessary
                passdata: {
                    metaData: {
                        reservationDate: `${serverDate} ${reservationData.time}`,
                        headcount: reservationData.partySize.toString()
                        // Converted to string based on previous discussions; 
                        // adjust if the server expects a number
                    }
                }
            };
    
            const response = await fetch('/updateCard', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(requestBody),
            });
    
            const responseData = await response.json();
    
            if (response.ok) {
                setSuccess(true);
            } else {
                setError(responseData.error || "Failed to update card");
            }
        } catch (error) {
            setError(error.message);
        } finally {
            setLoading(false);
        }
    };
    

    const renderPartySizeOptions = () => (
        [...Array(10)].map((_, i) => (
            <option key={i} value={i + 1}>
                {i + 1} {i + 1 === 1 ? 'person' : 'people'}
            </option>
        ))
    );

    return (
        <>
            {isLoading && (
                <div className="bambu_tm_loading-modal-content">
                    <FontAwesomeIcon icon="spinner" spin /> {/* Assuming FontAwesome's spinner icon */}
                    Sending...
                </div>
            )}
    
            {success && (
                <div className="success-container">
                    <div className="success-header">
                        <FontAwesomeIcon icon={faCheckCircle} className="icon-success" />
                        Reservation Confirmed!
                    </div>
    
                    <div className="success-details">
                        <p>
                            <FontAwesomeIcon icon={faCalendarAlt} className="icon-detail" />
                            <strong>Date: </strong> {reservationData.date}
                        </p>
                        <p>
                            <FontAwesomeIcon icon={faClock} className="icon-detail" />
                            <strong>Time: </strong> {reservationData.time}
                        </p>
                        <p>
                            <FontAwesomeIcon icon={faUsers} className="icon-detail" />
                            <strong>Party Size: </strong> {reservationData.partySize} 
                            {reservationData.partySize === "1" ? ' person' : ' people'}
                        </p>
                    </div>
                    
                    <div className="wallet-info">
                        Check your wallet card for further details.
                    </div>
                </div>
            )}
    
            {error && <div className="error-message">{error}</div>}
            
            {!isLoading && !success && (
                <div className="bambu_tm_input-fields-container">
                    <h2 className='bambu_tm-usecase-card'>Make a Reservation</h2>
                    <form onSubmit={handleSubmit}>
                        <div>
                            <input
                                name="date"
                                type="date"
                                value={reservationData.date}
                                onChange={handleChange}
                            />
                        </div>
                        <div className="form-group time-group">
                            <input
                                name="time"
                                type="time"
                                value={reservationData.time}
                                onChange={handleChange}
                            />
                        </div>
                        <div className="form-group party-size-group">
                            <div className="select-wrapper">
                                <select name="partySize" value={reservationData.partySize} onChange={handleChange}>
                                    {renderPartySizeOptions()}
                                </select>
                                <FontAwesomeIcon icon={faChevronDown} className="select-chevron" />
                            </div>
                        </div>
                        <button type="submit" className="button reserve-button">Reserve your spot</button>
                    </form>
                    <div className="open-table-logo">
                        powered by <span>•</span> OpenTable <span>•</span>
                    </div>
                </div>            
            )}
        </>
    );
    
}

export default ReservationForm;
