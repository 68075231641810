import React, { useState } from 'react';
import UseCasesCarousel from './UseCasesCarousel'; 
import UseCasesModal from './UseCasesModal'; 


const RestaurantPage = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedUseCase, setSelectedUseCase] = useState(null);

    const handleUseCaseClick = (useCase) => {
        console.log("Use case clicked:", useCase);
        setSelectedUseCase(useCase);
        setIsModalOpen(true);
    };

    return (
        <div className="restaurantModalSystem">
            <UseCasesCarousel onUseCaseClick={handleUseCaseClick}
            title={selectedUseCase?.title}
            />
            {console.log("Modal state:", isModalOpen)} 
            {isModalOpen && selectedUseCase && (  // Add "selectedUseCase" condition
                <UseCasesModal 
                isOpen={isModalOpen} 
                onClose={() => setIsModalOpen(false)} 
                useCaseTitle={selectedUseCase.title} 
            />
            
            )}
        </div>
    );
}

export default RestaurantPage;
