import React, { useState } from 'react';
import Modal from './modal';
import { PARTY_INFO } from '../../config/partyConfig';

function RockTheVote() {
    const [isModalOpen, setModalOpen] = useState(false);
    const [selectedParty, setSelectedParty] = useState(null);

    const handleButtonClick = party => {
        setSelectedParty(party);
        setModalOpen(true);
    };

    return (
        <div className="bambu_tm_rtv-rockTheVote">
            <main className="bambu_tm_rtv-main">
                <div className="hero-section">
                <div className="bambu_tm_rtv-hero-content">
                        <img src="https://coursework.vschool.io/content/images/2016/04/rock_the_vote_banner-22.jpg" alt="Rock The Vote Hero" className="hero-bg" />
                        <div className="bambu_tm_rtv-voting-section">
                            <div className="bambu_tm_rtv-voting-text">I am voting:</div>
                            <div className="bambu_tm_rtv-btn-container">
                                <button className="btn republican-btn" onClick={() => handleButtonClick('republican')}>Republican</button>
                                <button className="btn democrat-btn" onClick={() => handleButtonClick('democrat')}>Democrat</button>
                                <button className="btn independent-btn" onClick={() => handleButtonClick('independent')}>Independent</button>
                            </div>
                        </div>
                    </div>
                </div>
                <Modal 
                    isOpen={isModalOpen} 
                    onClose={() => setModalOpen(false)} 
                    party={selectedParty}
                    onSubmit={formData => {
                        console.log(formData);  // Handle the form submission logic here.
                        setModalOpen(false);
                    }}
                />
            </main>
        </div>
    );
}

export default RockTheVote;
