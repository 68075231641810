export const PARTY_INFO = {
    republican: {
        logo: "https://upload.wikimedia.org/wikipedia/commons/thumb/9/93/Republican_Disc.svg/640px-Republican_Disc.svg.png",
        candidates: ["Donald Trump", "Ron DeSantis", "Chris Christie"]
    },
    democrat: {
        logo: "https://newsforkids.net/wp-content/uploads/2018/02/1050px-DemocraticLogo.svg_.png",
        candidates: ["Joe Biden"]
    },
    independent: {
        logo: "https://cdn.centraljersey.com/wp-content/uploads/sites/26/2023/06/vote-g997edd78f_1280.png",
    }
};
